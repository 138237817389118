import React from 'react';
import PageWithHeader from "../common/PageWithHeader";
import { Card, CardContent, Grid, Paper, Typography } from '@mui/material';
import { Link, useRouteMatch } from 'react-router-dom';
import { Timer, Assessment, LocalShipping } from "@mui/icons-material";
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(() => ({
  height: '230px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#1976d2',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  transition: '0.3s',
  borderRadius: '15px',
  '&:hover': {
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
  },
}));

const StyledTypography = styled(Typography)({
  textAlign: 'center',
  color: '#fff',
});

const PlantReportsDashboard: React.FC = () => {
  const { url } = useRouteMatch();

  const options = [
    { id: 1, title: 'Log Hours', link: `${url}/log`, icon: <Timer sx={{ fontSize: 60, mb: 1, color: '#fff' }} /> },
    { id: 2, title: 'Reports', link: `${url}/reports`, icon: <Assessment sx={{ fontSize: 60, mb: 1, color: '#fff' }} /> },
    { id: 3, title: 'Manage Equipment', link: `${url}/manage`, icon: <LocalShipping sx={{ fontSize: 60, mb: 1, color: '#fff' }} /> },
  ];

  return (
    <PageWithHeader>
      <Paper elevation={2} sx={{ margin: 10, padding: 5, borderRadius: '15px' }}>
        <Typography variant='h1' gutterBottom sx={{ fontWeight: 'bold', color: '#123456' }}>
          Plant Reports
        </Typography>
        <Typography variant='body1' gutterBottom>
          Welcome to the Plant Reporting portal. This intuitive tool is designed to help you manage plant equipment
          efficiently, allowing you to submit and track daily hourly reports for each vehicle in the WDC fleet. Easily log
          usage data for an entire week at a time, ensuring accurate record-keeping and streamlined equipment management.
          Stay on top of your operations with this essential reporting solution.
        </Typography>

        <Grid container spacing={3} pt={3}>
          {options.map((option) => (
            <Grid item xs={6} sm={4} md={4} key={option.id}>
              <Link to={option.link} style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
                <StyledCard>
                  <CardContent style={{ textAlign: 'center' }}>
                    {option.icon}
                    <StyledTypography variant="h6">{option.title}</StyledTypography>
                  </CardContent>
                </StyledCard>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </PageWithHeader>
  );
}

export default PlantReportsDashboard;
