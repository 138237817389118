
const itemLocationFormModel = {
    formId: 'itemLocationForm',
    formField: {
      itemId: {
        name: 'itemId',
        label: 'Item',
        required: true,
        requiredErrorMsg: 'Item is required',
      },
      locationId: {
        name: 'locationId',
        label: 'Location',
        required: true,
        requiredErrorMsg: 'Location is required',
      },
      stock: {
        name: 'stock',
        label: 'Stock',
        required: true,
        requiredErrorMsg: 'Stock is required',
      },
      totalValue: {
        name: 'totalValue',
        label: 'Total Value',
        required: true,
  
      },
    },
    initialValues: {
        itemId: '',
        locationId:'',
        stock:'',
        totalValue:''     
    },
  };
  
  export type createItemLocationModelType = {
    itemId: string;
    locationId: string;
    stock: string;
    totalValue:string ;
  };
  
  export default itemLocationFormModel;
  