import React, { useState } from 'react';
import {
    BackOrder,
    useGetBackOrdersQuery
} from '../../../redux/vmsApi';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const BackOrdersList: React.FC = () => {
  const { data:backOrders, isLoading, isError } = useGetBackOrdersQuery({});
  const [selectedBackOrder, setSelectedBackOrder] = useState<BackOrder | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleEditClick = (backOrder:BackOrder) => {
    setSelectedBackOrder(backOrder);
    setIsDialogOpen(true);
  };


  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error fetching BackOrders.</div>;
  }

  return (
    <Box p={10}>
      <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
          Back Orders 
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item Code</TableCell>
              <TableCell>Supplier</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit Cost</TableCell>
              
              <TableCell>Total Value</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {backOrders?.map((backOrder:any) => (
              <TableRow key={backOrder.id}>
                <TableCell>{backOrder.item.itemCode},{backOrder.item.description}</TableCell>
                <TableCell>{backOrder.stakeholder.name},{backOrder.stakeholder.contactInfo[0].email}</TableCell>
                <TableCell>{backOrder.stock}</TableCell>
                <TableCell>{backOrder.item.itemCost}</TableCell> 
                <TableCell>{backOrder.totalValue}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEditClick(backOrder)}>Edit</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
     
    </Box>
  );
};

export default BackOrdersList;
