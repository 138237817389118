import React from 'react';
import {Paper, Typography} from "@mui/material";


import ProcurementContainer from '../../procurement/containers/ProcurementContainer';
import CreateItemCategoryComponent from '../component/CreateItemCategoryComponent';
import ItemCategoriesList from '../lists/itemCategoryList';



const ItemCategoriesPage: React.FC = () => {
  return (
    <ProcurementContainer>
      <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        Item Categories
      </Typography>
      <Paper sx={{my: 5}}>
        <CreateItemCategoryComponent/>
      </Paper>
      <Paper sx={{my: 5}}>
        <ItemCategoriesList/>
      </Paper>
    </ProcurementContainer>

  )
}

export default ItemCategoriesPage