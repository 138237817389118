const inventoryFormModel = {
    formId: 'InventoryForm',
    formField: {
      description: {
        name: 'description',
        label: 'Description',
        required: true,
        requiredErrorMsg: 'Description is required',
      },
      inventoryCode: {
        name: 'inventoryCode',
        label: 'Inventory Code',
        required: true,
        requiredErrorMsg: 'inventoryCode is required',
      },
    },
    initialValues: {
        inventoryCode: '',
        description:'',    
    },
  };
  
  export type createInventoryModelType = {
    inventoryCode: string;
    description: string;
  };
  
  export default inventoryFormModel;
  