const itemLocationFormModel = {
    formId: 'itemLocationForm',
    formField: {
      stock: {
        name: 'stock',
        label: 'Quantity',
        required: true,
        requiredErrorMsg: 'Quantity is required',
      },
      itemId: {
        name: 'itemId',
        label: 'Item Code',
        required: true,
        requiredErrorMsg: 'item code is required',
      },
      locationId: {
        name: 'locationId',
        label: 'Location',
        required: true,
        requiredErrorMsg: 'Location is required',
      },
      totalValue: {
        name: 'totalValue',
        label: 'Total Value',
        required: true,
        requiredErrorMsg: 'Location is required',
      },
    },
    initialValues: {
        itemId: '',
        locationId:'',
        totalValue:'',
        stock:'',
       
    },
  };
  
  export type createItemModelType = {
    itemId: string;
    value: string;
    stock: string;
    totalValue:string;
  };
  
  export default itemLocationFormModel;
  