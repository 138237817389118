const billOfMaterialFormModel = {
  formId: 'billOfQuantitiesForm',
  formField: {
    project: {
      name: 'project',
      label: 'Project',
      required: true,
      requiredErrorMsg: 'Project is required',
    },
    unitId: {
      name: 'unitId',
      label: 'Unit',
      required: true,
      requiredErrorMsg: 'Unit is required',
    },
    sections: {
      name: 'billSections',
      label: 'Section bills Items',
      required: true,
      requiredErrorMsg: 'At least one item is required',
    },
    sectionNumber: {
      name: 'sectionNo',
      label: 'Section Number',
      required: true,
      requiredErrorMsg: 'Section number is required',
    },
    sectionTitle: {
      name: 'sectionTitle',
      label: 'Section Title',
      required: true,
      requiredErrorMsg: 'Section title is required',
    },
    bomItems: {
      name: 'items',
      label: 'Items',
      required: true,
      requiredErrorMsg: 'At least one item is required',
    },
    itemId: {
      name: 'itemId',
      label: 'Item ID',
      required: true,
      requiredErrorMsg: 'Item ID is required',
    },
    description: {
      name: 'description',
      label: 'Description',
      required: true,
      requiredErrorMsg: 'Description is required',
    },
    quantity: {
      name: 'quantity',
      label: 'Quantity',
      required: true,
      requiredErrorMsg: 'Quantity is required',
    },
    quantityUnit: {
      name: 'quantityUnit',
      label: 'Quantity Unit',
      required: true,
      requiredErrorMsg: 'Quantity Unit is required',
    },
    rate: {
      name: 'rate',
      label: 'Rate',
      required: true,
      requiredErrorMsg: 'Rate is required',
    },
    itemCode: {
      name: 'itemCode',
    },
    totalAmount: {
      name: 'totalAmount',
      label: 'Total Amount',
      required: true,
      requiredErrorMsg: 'Total Amount is required',
    },
  },
  initialValues: {
    unitId: '',
    sections: [{
      sectionNumber: '',
      sectionTitle: '',
      items: [{
        itemId: '',
        description: '',
        quantity: 0,
        quantityUnit: '',
        rate: 0,
        itemCode:''
      }],
    }],
  },
};

export type BomItem = {
  itemId: string;
  description: string;
  quantity: number;
  quantityUnit: string;
  rate: number;
  itemCode:string;
};

export type BomSection = {
  sectionNumber: string;
  sectionTitle: string;
  items: BomItem[];
};

export type createBillOfMaterialModelType = {
  sections: BomSection[];
  unitId: string;
};

export default billOfMaterialFormModel;
