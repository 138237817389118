import React, { useState } from 'react';
import {
  Item,
  useGetItemsQuery,
  useGetItemTransfersQuery,
} from '../../../redux/vmsApi';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const ItemTransfersList: React.FC = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState('');

  const { data: itemTransfers, isLoading, isError } = useGetItemTransfersQuery({
    skip: page * pageSize,
    limit: pageSize,
  });

  const handlePageChange = (newPage: number) => setPage(newPage);

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    setPageSize(Number(event.target.value));
    setPage(0);
  };

  const handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilter(event.target.value as string);
    setPage(0);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error fetching item Transfers.</div>;
  }

  return (
    <Box p={10}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontWeight: 'bold', color: '#123456', textAlign: 'center' }}
      >
      Transfer History
      </Typography>

      <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
        <Table>
          <TableHead sx={{ backgroundColor: '#1976d2' }}>
            <TableRow>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Items</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Source</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Destination</TableCell>
              <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Supporting Documents</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemTransfers && itemTransfers.length > 0 ? (
              itemTransfers.map((itemTransfer: any) => (
                <TableRow key={itemTransfer.id}>
                  
                  <TableCell>
                    <Box display="flex" flexDirection="column" gap={2}>
                      {itemTransfer.items.map((item: any, index: number) => (
                        <Card
                          key={index}
                          sx={{
                            borderRadius: 2,
                            boxShadow: 1,
                            padding: 2,
                            backgroundColor: '#f9f9f9',
                            border: '1px solid #ddd',
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 'bold', color: '#1976d2' }}
                          >
                          {item.description}
                          </Typography>
                          <Box display="flex" justifyContent="space-between" mt={1}>
                            <Typography variant="body2" sx={{ color: '#555' }}>
                              Quantity: {item.quantity.quantityValue}{' '}
                              <small>({item.quantity.quantityUnit})</small>
                            </Typography>
                            <Typography variant="body2" sx={{ color: '#555' }}>
                              Reason: {item.reasonForTransfer}
                            </Typography>
                          </Box>
                        </Card>
                      ))}
                    </Box>
                  </TableCell>

                 
                  <TableCell>
                    <Typography variant="body2">
                      {itemTransfer.sourceLocationType === 'inventoryLocation' && (
                        <>
                          <strong>Code:</strong> {itemTransfer.sourceLocation.inventoryLocation.locationCode}
                          <br />
                          <strong>Description:</strong> {itemTransfer.sourceLocation.inventoryLocation.description}
                        </>
                      )}
                      {itemTransfer.sourceLocationType === 'unit' && (
                        <>
                          <strong>Development:</strong> {itemTransfer.sourceLocation.development.name}
                          <br />
                          <strong>Project:</strong> {itemTransfer.sourceLocation.project.name}
                          <br />
                          <strong>Unit:</strong> {itemTransfer.sourceLocation.unit.name}
                        </>
                      )}
                      {itemTransfer.sourceLocationType === 'supplier' && (
                        <>
                          <strong>Supplier:</strong> {itemTransfer.sourceLocation.supplier.name}
                        </>
                      )}
                    </Typography>
                  </TableCell>


                 
                  <TableCell>
                    <Typography variant="body2">
                      {itemTransfer.destinationLocationType === 'inventoryLocation' && (
                        <>
                          <strong>Code:</strong> {itemTransfer.destinationLocation.inventoryLocation.locationCode}
                          <br />
                          <strong>Description:</strong> {itemTransfer.destinationLocation.inventoryLocation.description}
                        </>
                      )}
                      {itemTransfer.destinationLocationType === 'unit' && (
                        <>
                          <strong>Development:</strong> {itemTransfer.destinationLocation.development.name}
                          <br />
                          <strong>Project:</strong> {itemTransfer.destinationLocation.project.name}
                          <br />
                          <strong>Unit:</strong> {itemTransfer.destinationLocation.unit.name}
                        </>
                      )}
                      {itemTransfer.destinationLocationType === 'supplier' && (
                        <>
                          <strong>Supplier:</strong> {itemTransfer.destinationLocation.supplier.name}
                        </>
                      )}
                    </Typography>
                  </TableCell>


                
                  <TableCell>
                    <Button variant="outlined" size="small">
                      View Supporting Documents
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No matching item transfer found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

     
      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <Button
          onClick={() => handlePageChange(Math.max(page - 1, 0))}
          disabled={page === 0}
          variant="contained"
          sx={{ marginRight: 2 }}
        >
          Previous
        </Button>
        <Typography variant="body1">Page {page + 1}</Typography>
        <Button
          onClick={() => handlePageChange(page + 1)}
          disabled={!itemTransfers || itemTransfers.length < pageSize}
          variant="contained"
          sx={{ marginLeft: 2 }}
        >
          Next
        </Button>
      </Box>

    
      <Box mt={2} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body2" sx={{ marginRight: 2 }}>
          Rows per page:
        </Typography>
        <FormControl variant="outlined" size="small">
          <Select value={pageSize} onChange={handlePageSizeChange} sx={{ backgroundColor: '#f0f4f7' }}>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>

  );
};

export default ItemTransfersList;
