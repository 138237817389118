import React, { useState } from 'react';
import {
    ItemLocation,
  useGetItemLocationsQuery, 
} from '../../../redux/vmsApi';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const ItemLocationsList: React.FC = () => {
  const { data:itemLocations, isLoading, isError } = useGetItemLocationsQuery({});
  const [selectedItemLocation, setSelectedItemLocation] = useState<ItemLocation | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleEditClick = (itemLocation:ItemLocation) => {
    setSelectedItemLocation(itemLocation);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedItemLocation(null);
  };


  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error fetching itemLocations.</div>;
  }

  return (
    <Box p={10}>
      <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
          Item Stock Levels in Particular locations
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item Code</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit Cost</TableCell>
              
              <TableCell>Total Value</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemLocations?.map((itemLocation:any) => (
              <TableRow key={itemLocation.id}>
                <TableCell>{itemLocation.item.itemCode},{itemLocation.item.description}</TableCell>
                <TableCell>{itemLocation.location.locationCode},{itemLocation.location.description}</TableCell>
                <TableCell>{itemLocation.stock}</TableCell>
                <TableCell>{itemLocation.item.itemCost}</TableCell> 
                <TableCell>{itemLocation.totalValue}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEditClick(itemLocation)}>Edit</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
     
    </Box>
  );
};

export default ItemLocationsList;
