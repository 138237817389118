import React, { useEffect } from 'react';
import { Form, FieldArray, Field } from 'formik';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, MenuItem, Select, SelectChangeEvent, Stack, Switch, TextField, Typography } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import DropzoneArea from '../../common/DropzoneArea';
import { useGetStakeholdersByTypeByTypeQuery } from '../../../redux/vmsApi';

import { Delete as DeleteIcon } from '@mui/icons-material';

const unitOptions = [
  { value: 'm' },
  { value: 'm²' },
  { value: 'm³' },
  { value: 'kg' },
  { value: 'lt' },
  { value: 'item' },
  { value: 'no unit' },
];

const formatUSD = (amount: number) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);

const QuotationForm: React.FC<any> = ({ initialValues, handleSubmit, setFieldValue, values, showQuotationForm, setShowQuotationForm, procurementProcess }) => {
  const { data: stakeholders } = useGetStakeholdersByTypeByTypeQuery({ type: 'Supplier' }); 
  const handleApproval = async (isApproved: boolean) => {
    if (!values.comment && !showQuotationForm) {
      toast.error("Please provide a comment before proceeding.", {
        position: toast.POSITION.TOP_CENTER,
        theme: 'colored',
      });
      return;
    } else if (showQuotationForm && (values.quotations[0]?.attachments?.length === 0 || values.quotations.length === 0)) {
      toast.error("Please provide at least one quotation.", {
        position: toast.POSITION.TOP_CENTER,
        theme: 'colored',
      });
      return;
    } else {
      console.log("showQuotationForm", showQuotationForm);
      console.log("values.quotations.length", values.quotations[0]?.attachments?.length);
      values.approved = isApproved;
      console.log("Form values before submission: ", values);

      handleSubmit(values);
    }
  };


  const handleSupplierChange = (event: SelectChangeEvent<any>, index: number) => {
    const supplierId = event.target.value;
    const supplierObject = stakeholders?.find((supplier: any) => supplier.id === supplierId); const supplierName = supplierObject ? supplierObject.name : '';

    const updatedQuotations = [...values.quotations];
    updatedQuotations[index] = {
      ...updatedQuotations[index],
      supplier: { id: supplierId, name: supplierObject?.name || '' },
      paymentTerms: supplierObject?.paymentTerms || '',
    };
    setFieldValue('quotations', updatedQuotations);
  };


  const formatUSD = (amount: number) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);


  const calculateTotalWithoutDiscount = (items: any) => {
    return items.reduce((acc: number, item: any) => {
      const baseCost = item.quantity.quantityValue * item.rate;
      const VAT = item.VAT ? 0.15 * item.rate * item.quantity.quantityValue : 0;
      return acc + baseCost + VAT;
    }, 0);
  };


  const calculateTotalWithDiscount = (items: any, globalDiscount: number) => {
    const totalWithoutDiscount = calculateTotalWithoutDiscount(items);
    const totalCostSum = items.reduce((acc: number, item: any) => acc + (item.totalCostPerItem || 0), 0);
    return globalDiscount > 0 ? totalWithoutDiscount - globalDiscount : totalCostSum;
  };

  useEffect(() => {
    values.quotations.forEach((quotation: any, index: number) => {
      const totalVAT = quotation.quotationItems.reduce((acc: number, item: any) => {
        const VAT = item.VAT ? 0.15 * (item.rate - (item.discountPerItem || 0)) * item.quantity.quantityValue : 0;
        return acc + VAT;
      }, 0);
      const totalWithDiscount = calculateTotalWithDiscount(quotation.quotationItems, quotation.discount || 0);
      const totalb4VAT = totalWithDiscount - totalVAT;

      const totalDiscount = quotation.quotationItems.reduce((acc: number, item: any) => {
        const itemDiscount = (item.discountPerItem || 0) * (item.quantity.quantityValue || 0);
        return acc + itemDiscount;
      }, 0);
      setFieldValue(`quotations[${index}].totalVAT`, totalVAT);
      setFieldValue(`quotations[${index}].totalb4VAT`, totalb4VAT);
      setFieldValue(`quotations[${index}].grandTotal`, totalWithDiscount);
      setFieldValue(`quotations[${index}].totalDiscount`, totalDiscount);
    });
  }, [values.quotations, setFieldValue]);
  


  useEffect(() => {
    if (procurementProcess?.requisition?.items) {
      setFieldValue(
        'quotations',
        values.quotations.map((quotation: any) => ({
          ...quotation,
          quotationItems: procurementProcess.requisition.items.map((item: any) => ({
            description: item.description,
            quantity: { quantityValue: item.quantity.quantityValue, quantityUnit: item.quantity.quantityUnit },
            rate: item.rate || 0,
            totalCostPerItem: (item.quantityValue || 0) * (item.rate || 0),
            VAT: 0,
            discountPerItem: 0
          })),
        }))
      );
    }
  }, [procurementProcess?.requisition?.items, setFieldValue]);

  return (
    <Form>
      <ToastContainer />
      <Stack spacing={4} width="100%" p={10} pb={5}>
        {!showQuotationForm && (
          <>

            <TextField
              name="comment"
              label="Comments"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              onChange={(e) => setFieldValue("comment", e.target.value)}
            />


            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Stack direction="row" spacing={2}>
                <ConfirmationDialog
                  actionType="Approve"

                  onConfirm={() => handleApproval(true)}
                />
                <ConfirmationDialog
                  actionType="Reject"

                  onConfirm={() => handleApproval(false)}
                />
              </Stack>
            </Stack>
          </>
        )}        {showQuotationForm && (
          <FieldArray name="quotations">
            {({ push: pushQuotation, remove: removeQuotation }) => (
              <Stack spacing={3}>
                {values.quotations.map((quotation: any, qIndex: number) => (
                  <Card key={qIndex} sx={{ margin: 2, padding: 2, backgroundColor: '#f5f5f5', borderRadius: 4, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '100%' }}>
                    <CardHeader
                      title={<Typography variant="h6" sx={{ color: '#1976d2', fontWeight: 'bold' }}>Supplier Details</Typography>}
                      action={<IconButton onClick={() => removeQuotation(qIndex)} color="error"><RemoveCircleOutline /></IconButton>}
                    />
                    <Divider sx={{ marginY: 2 }} />
                    <CardContent>
                      <Grid container spacing={2} alignItems="flex-start">
                        <Grid item xs={12} md={4}>
                          <Typography variant="subtitle1" gutterBottom>Supplier</Typography>
                          <Select
                            name={`quotations.${qIndex}.supplier`}
                            fullWidth
                            value={quotation.supplier?.id || ''}
                            onChange={(e) => handleSupplierChange(e, qIndex)}
                            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                          >
                            {stakeholders?.map((stakeholder: any) => (
                              <MenuItem key={stakeholder.id} value={stakeholder.id}>{stakeholder.name}</MenuItem>
                            ))}
                          </Select>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                            Payment Terms
                          </Typography>
                          <TextField
                            name={'quotations.${qIndex}.paymentTerms'}
                            fullWidth
                            variant="outlined"
                            value={quotation.paymentTerms}
                            InputProps={{
                              readOnly: true,
                            }}
                            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                          />

                        </Grid>


                        <Grid item xs={12} md={4}>
                          <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                            Remarks
                          </Typography>
                          <TextField
                            name={`quotations.${qIndex}.remarks`}
                            fullWidth
                            variant="outlined"
                            value={quotation.remarks}
                            onChange={(e) => setFieldValue(`quotations.${qIndex}.remarks`, e.target.value)}
                            placeholder="Enter remarks"
                            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                          />

                        </Grid>
                        {/* Global discount no longer necessary since it's applied per line itrm before VAT */}
                        {/* <Grid item xs={12} md={3}>
                          <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                            Discount
                          </Typography>
                          <TextField
                            name={`quotations.${qIndex}.discount`}
                            type="number"
                            fullWidth
                            variant="outlined"
                            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                            value={quotation.discount || 0}
                            onChange={(e) => {
                              const discount = parseFloat(e.target.value) || 0;
                              setFieldValue(`quotations.${qIndex}.discount`, discount);


                              if (discount > 0) {
                                quotation.quotationItems.forEach((_: any, iIndex: number) => {
                                  setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.discountPerItem`, 0);
                                });
                              }
                            }}
                            placeholder="Enter global discount"
                            disabled={quotation.quotationItems.some((item: any) => item.discountPerItem > 0)}
                          />
                        </Grid> */}


                        <Field name={`quotations.${qIndex}.totalVAT`} type="hidden" />
                        <Field name={`quotations.${qIndex}.totalDiscount`} type="hidden" />
                        <Field name={`quotations.${qIndex}.totalb4VAT`} type="hidden" />
                        <Field name={`quotations.${qIndex}.grandTotal`} type="hidden" />

                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography variant="subtitle1" gutterBottom sx={{ color: 'textSecondary' }}>
                          Attachments
                        </Typography>
                        <DropzoneArea
                          setFieldValue={setFieldValue}
                          fieldName={`quotations.${qIndex}.attachments`}
                          attachments={quotation.attachments || []}
                        />
                      </Grid>
                      <FieldArray name={`quotations.${qIndex}.quotationItems`}>
                        {({ push: pushItem, remove: removeItem }) => (
                          <Stack spacing={2} mt={3}>
                            {quotation.quotationItems.map((item: any, iIndex: number) => (
                              <Stack direction="row" spacing={2} alignItems="center" key={iIndex}>
                                <TextField
                                  name={`quotations.${qIndex}.quotationItems.${iIndex}.description`}
                                  label="Description"
                                  fullWidth
                                  value={item.description}
                                  onChange={(e) => setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.description`, e.target.value)}
                                  sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />
                                <TextField
                                  name={`quotations.${qIndex}.quotationItems.${iIndex}.quantity.quantityValue`}
                                  label="Quantity"
                                  type="number"
                                  value={item.quantity.quantityValue}
                                  onChange={(e) => {
                                    const quantityValue = parseFloat(e.target.value) || 0;
                                    const baseCost = (item.rate - item.discountPerItem) * quantityValue;
                                    const VAT = item.VAT ? 0.15 * (item.rate - item.discountPerItem) * quantityValue : 0;
                                    const totalCost = baseCost + VAT;

                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.quantity.quantityValue`, e.target.value);
                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.totalCostPerItem`, totalCost);
                                  }}
                                  sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />
                                <Select
                                  name={`quotations.${qIndex}.quotationItems.${iIndex}.quantity.quantityUnit`}
                                  value={item.quantity.quantityUnit || ''}
                                  onChange={(e) => setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.quantity.quantityUnit`, e.target.value)}
                                  sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                >
                                  {unitOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>{option.value}</MenuItem>
                                  ))}
                                </Select>

                                <TextField
                                  name={`quotations.${qIndex}.quotationItems.${iIndex}.rate`}
                                  label="Rate"
                                  type="number"
                                  value={item.rate || 0}
                                  onChange={(e) => {
                                    const rate = parseFloat(e.target.value) || 0;
                                    const baseCost = (rate - item.discountPerItem) * item.quantity.quantityValue;
                                    const VAT = item.VAT ? 0.15 * (item.rate - item.discountPerItem) * item.quantity.quantityValue : 0;
                                    console.log("<-----------VAT---------------------->", VAT)
                                    const totalCost = baseCost + VAT;

                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.rate`, rate);
                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.totalCostPerItem`, totalCost);
                                  }}
                                  sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />






                                <TextField
                                  name={`quotations.${qIndex}.quotationItems.${iIndex}.discountPerItem`}
                                  label="Discount per Item"
                                  type="number"
                                  value={item.discountPerItem || 0}
                                  onChange={(e) => {
                                    const discountPerItem = parseFloat(e.target.value) || 0;
                                    const baseCost = (item.rate - discountPerItem) * item.quantity.quantityValue;
                                    const VAT = item.VAT ? 0.15 * (item.rate - item.discountPerItem) : 0;
                                    const totalCost = baseCost + VAT;
                                    console.log("totalCost", totalCost)
                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.discountPerItem`, discountPerItem);
                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.totalCostPerItem`, totalCost);


                                    if (discountPerItem > 0) {
                                      setFieldValue(`quotations.${qIndex}.discount`, 0);
                                    }
                                  }}
                                  sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                  disabled={quotation.discount > 0}
                                />

                                <Switch
                                  checked={item.VAT || false}
                                  onChange={(e) => {
                                    const VATEnabled = e.target.checked;
                                    const baseCost = (item.rate - item.discountPerItem) * item.quantity.quantityValue;
                                    const VAT = VATEnabled ? 0.15 * (item.rate - item.discountPerItem) * item.quantity.quantityValue : 0;
                                    const totalCost = baseCost + VAT;

                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.VAT`, VATEnabled);
                                    setFieldValue(`quotations.${qIndex}.quotationItems.${iIndex}.totalCostPerItem`, totalCost);
                                  }}
                                />
                                <Typography>VAT: {item.VAT ? "Yes (15%)" : "No"}</Typography>

                                <TextField
                                  name={`quotations.${qIndex}.quotationItems.${iIndex}.totalCostPerItem`}
                                  label="Total Amount"
                                  value={formatUSD(item.totalCostPerItem || 0)}
                                  disabled
                                  sx={{ backgroundColor: '#fff', borderRadius: 1 }}
                                />


                                <IconButton color="error" onClick={() => removeItem(iIndex)}><DeleteIcon /></IconButton>
                              </Stack>
                            ))}
                            <Button
                              onClick={() => pushItem({ description: '', quantity: { quantityValue: 0, quantityUnit: '' }, rate: 0, totalCostPerItem: 0 })}
                              variant="contained"
                              size="small"
                            >
                              Add Item
                            </Button>
                          </Stack>
                        )}
                      </FieldArray>
                    </CardContent>
                    <Card sx={{ padding: '15px', backgroundColor: '#d1e7dd', textAlign: 'right', borderRadius: 2 }}>
                      <Stack direction="column" spacing={1}>



                        {/* this includes VAT but excludes discount  */}
                        {/* <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="subtitle2" sx={{ color: '#333', fontWeight: 'bold' }}>
                            Total (Excl. Discount):
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: '#333' }}>
                            {formatUSD(calculateTotalWithoutDiscount(quotation.quotationItems))}
                          </Typography>
                        </Box> */}

                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="subtitle2" sx={{ color: '#333', fontWeight: 'bold' }}>
                            Total (Excl. VAT):
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: '#333' }}>
                            {formatUSD(
                              calculateTotalWithDiscount(quotation.quotationItems, quotation.discount || 0) -
                              quotation.quotationItems.reduce((acc: number, item: any) => {
                                const VAT = item.VAT ? 0.15 * (item.rate - item.discountPerItem) * item.quantity.quantityValue : 0;
                                return acc + VAT;
                              }, 0)
                            )}
                          </Typography>
                        </Box>

                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="subtitle2" sx={{ color: '#333', fontWeight: 'bold' }}>
                            VAT(15%):
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: '#333' }}>
                            {formatUSD(quotation.totalVAT || 0)}
                          </Typography>

                        </Box>

                        {/* So this is our total including VAT and discount (Incl. Discount) that we will use for PO */}
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="subtitle2" sx={{ color: '#333', fontWeight: 'bold' }}>
                            Grand Total :
                          </Typography>
                          <Typography variant="subtitle2" sx={{ color: '#333' }}>
                            {formatUSD(calculateTotalWithDiscount(quotation.quotationItems, quotation.discount || 0))}
                          </Typography>
                        </Box>
                      </Stack>
                    </Card>

                  </Card>
                ))}
                <Button variant="outlined" startIcon={<AddCircleOutline />} onClick={() => pushQuotation({ supplier: '', paymentTerms: '', remarks: '', attachments: [], quotationItems: procurementProcess.requisition.items })}>Add Quotation</Button>
              </Stack>
            )}
          </FieldArray>
        )}

        <ConfirmationDialog
          actionType="Submit Info"

          onConfirm={() => handleApproval(true)}
        />
        <Button color="primary" variant="outlined" onClick={() => setShowQuotationForm(false)}>Go back Dashboard</Button>
      </Stack>
    </Form>
  );
};

export default QuotationForm;
