import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PlantReportsDashboard from "./PlantReportsDashboard";
import Log from "./Log";
import Reports from "./Reports";
import Manage from "./Manage";

const PlantReportsRoutes = () => {
  return (
    <Switch>
      <Route exact path="/plant-reports" component={PlantReportsDashboard} />
      <Route exact path="/plant-reports/log" component={Log} />
      <Route exact path="/plant-reports/reports" component={Reports} />
      <Route exact path="/plant-reports/manage" component={Manage} />
    </Switch>
  );
}

export default PlantReportsRoutes;
