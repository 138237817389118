import React, {useEffect, useState} from 'react';
import {Button, Stack, Typography} from "@mui/material";
import {Form, Formik} from 'formik';



import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {enGB} from 'date-fns/locale'
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { ItemCategory, NewItemCategory, usePostItemCategoriesMutation,  } from '../../../redux/vmsApi';

import LoadingCover from '../../common/LoadingCover';
import createItemCategoryModel, { createItemCategoryModelType } from '../models/createItemCategoryModel';
import CreateItemCategoryForm from '../forms/CreateItemCategoryForm';




let newItemCategory:NewItemCategory;
type MyFormValues = createItemCategoryModelType

const createItemCategoryModelInitial = createItemCategoryModel.initialValues

type Props = {
  onSuccess?: (ItemCategory: ItemCategory) => void;
};

const CreateItemCategoryPage: React.FC<Props> = ({onSuccess}) => {
  const [createItemCategory, {isSuccess, isLoading}] = usePostItemCategoriesMutation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  useEffect(() => {
    setShowOverlay(isLoading || isSuccess);
    if (isSuccess) {
      setTimeout(() => {
        setShowOverlay(false)
        setShowSuccessMsg(true)
      }, 3000);
    }
  }, [isLoading, isSuccess]);

  const initialValues: MyFormValues = {
    ...createItemCategoryModelInitial,
  };

  

  const handleSubmit = async (values: MyFormValues) => {
   
    console.log("+++++++values.categoryCode++++++++",values.categoryCode)
    const newItemCategory = {
        categoryCode: values.categoryCode,
        description: values.description,      
    }
    console.log("+++++++data++++++++",newItemCategory)
    
   
    setShowOverlay(true)
    try {
      const result = await createItemCategory({newItemCategory}).unwrap();
      setShowOverlay(false);
      setShowOverlay(false);
      
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {
     
      setShowOverlay(false);
      console.error("Error submitting form:", error);
    }
  }

  if (showSuccessMsg) {
    return (
      <Typography>Thank you, your response has been received</Typography>
    )
  }

  return (
    <React.Fragment>
      {showOverlay && <LoadingCover isSuccess={isSuccess}/>}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <Form>
            <Stack spacing={4} width="100%" p={10} pb={5}>
              <CreateItemCategoryForm/>
              <Button
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            </Stack>
          </Form>
        </LocalizationProvider>
      </Formik>
      </React.Fragment>
  )
}

export default CreateItemCategoryPage;
