import React from 'react';
import {Paper, Typography} from "@mui/material";

import CreateItemComponent from '../component/CreateItemComponent';
import ProcurementContainer from '../../procurement/containers/ProcurementContainer';
import InventoryList from '../lists/inventoryList';
import CreateInventoryComponent from '../component/CreateInventoryComponent';


const InventoryPage: React.FC = () => {
  return (
    <ProcurementContainer>
      <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
       Inventory
      </Typography>
      <Paper sx={{my: 5}}>
        <CreateInventoryComponent/>
      </Paper>
      <Paper sx={{my: 5}}>
        <InventoryList/>
      </Paper>
    </ProcurementContainer>

  )
}

export default InventoryPage