import { Category } from "@mui/icons-material";

const itemFormModel = {
    formId: 'itemForm',
    formField: {
      description: {
        name: 'description',
        label: 'Description',
        required: true,
        requiredErrorMsg: 'Description is required',
      },
      quantityUnit: {
        name: 'quantityUnit',
        label: 'Unit',
        required: true,
        requiredErrorMsg: 'Quantity Unit is required',
      },
      categoryId: {
        name: 'categoryId',
        label: 'Category',
        required: true,
        requiredErrorMsg: 'Category is required',
      },
      itemCode: {
        name: 'itemCode',
        label: 'Item Code',
        required: true,
        requiredErrorMsg: 'itemCode is required',
      },
      itemCost: {
        name: 'itemCost',
        label: 'Item Cost',
        required: true,
        requiredErrorMsg: 'itemCost is required',
      },
      inventoryId: {
        name: 'inventoryId',
        label: 'inventory Code',
        required: true,
        requiredErrorMsg: 'inventory Code is required',
      },
    },
    initialValues: {
        itemCode: '',
        description:'',
        quantityUnit:'',
        categoryId:'',
        itemCost: 0, 
        inventoryId:''    
    },
  };
  
  export type createItemModelType = {
    itemCode: string;
    description: string;
    quantityUnit: string;
    categoryId: string;
    itemCost: number;
    inventoryId: string;
  };
  
  export default itemFormModel;
  