import React, { useState } from 'react';
import {
  ItemIssued,
  useGetItemsIssuedQuery,
  useGetUnitsQuery, 
} from '../../../redux/vmsApi';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const ItemsIssuedList: React.FC = () => {
  const { data:itemsIssued, isLoading, isError } = useGetItemsIssuedQuery({});
  const [selectedItemIssued, setSelectedItemIssued] = useState<ItemIssued | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleEditClick = (ItemsIssued:ItemIssued) => {
    setSelectedItemIssued(ItemsIssued);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedItemIssued(null);
  };


  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error fetching ItemsIssued.</div>;
  }

  return (
    <Box p={10}>
      <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
          Items Issued to Housing Units
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item Code</TableCell>
              <TableCell>Housing Unit</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit Cost</TableCell>
              
              <TableCell>Total Value</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemsIssued?.map((itemIssued:any) => (
              <TableRow key={itemIssued.id}>
                <TableCell>{itemIssued.item.itemCode},{itemIssued.item.description}</TableCell>
                <TableCell>{itemIssued.unit.unitName}</TableCell>
                <TableCell>{itemIssued.stock}</TableCell>
                <TableCell>{itemIssued.item.itemCost}</TableCell> 
                <TableCell>{itemIssued.totalValue}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEditClick(itemIssued)}>Edit</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
     
    </Box>
  );
};

export default ItemsIssuedList;
