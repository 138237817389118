import React from 'react';
import {Paper, Typography} from "@mui/material";

import CreateItemComponent from '../component/CreateItemComponent';
import ProcurementContainer from '../../procurement/containers/ProcurementContainer';
import CreateItemsIssuedComponent from '../component/CreateItemsIssuedComponent';
import ItemsIssuedList from '../lists/itemsIssuedList';





const ItemsIssuedPage: React.FC = () => {
  return (
    <ProcurementContainer>
      <Typography variant='h1' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        Items Issued to Housing Units
      </Typography>
      <Paper sx={{my: 5}}>
        <CreateItemsIssuedComponent/>
      </Paper>
      <Paper sx={{my: 5}}>
        <ItemsIssuedList/>
      </Paper>
    </ProcurementContainer>

  )
}

export default ItemsIssuedPage