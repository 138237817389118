import React, { useState } from 'react';
import {
  InventoryLocation,
  useGetInventoryLocationsQuery,
} from '../../../redux/vmsApi';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const InventoryLocationsList: React.FC = () => {
  const { data: inventoryLocations, isLoading, isError } = useGetInventoryLocationsQuery({});
  const [selectedInventoryLocation, setSelectedInventoryLocation] = useState<InventoryLocation | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleEditClick = (InventoryLocation: InventoryLocation) => {
    setSelectedInventoryLocation(InventoryLocation);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedInventoryLocation(null);
  };


  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error fetching InventoryLocations.</div>;
  }

  return (
    <Box p={10}>
      <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        View Inventory Locations
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Development</TableCell>
              <TableCell>Inventory Location Code</TableCell>
              <TableCell>Description</TableCell>
             
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventoryLocations?.map((inventoryLocation: any) => (
              <TableRow key={inventoryLocation.id}>
                <TableCell>{inventoryLocation.development.name}</TableCell>
                <TableCell>{inventoryLocation.locationCode}</TableCell>      
                <TableCell>{inventoryLocation.description}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEditClick(inventoryLocation)}>Edit</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
     
    </Box>
  );
};

export default InventoryLocationsList;
