import React, { useState, useEffect } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useGetUsersMeQuery, useGetProcurementProcessesByIdQuery, usePutProcurementProcessesByIdMutation, useGetDevelopmentsByIdQuery, Quotation, RequisitionItem } from '../../redux/vmsApi';
import PageWithHeader from '../common/PageWithHeader';
import { Formik } from 'formik';
import createRequisitionReviewModel, { RequisitionReviewType } from '../forms/models/createRequisitionReviewModel';
import LoadingCover from '../common/LoadingCover';
import ProcurementProcessDashboard from './dashboards/ProcurementProcessDashboard';
import QuotationForm from './forms/OuotationForm';
import { toast } from 'react-toastify';
import RenderRequisitionDetails from './common/RenderRequisitionDetails';
import RenderRequisitionHeader from './common/renderRequisitionHeader';


interface OwnProps {
  match?: any;
}
let updatedQuotationsAfterSelection: Quotation[];
const ViewRequisition: React.FC<OwnProps> = ({ match }) => {

  const { data: procurementProcess, isLoading, isError } = useGetProcurementProcessesByIdQuery({ id: match.params.uuid });
  const [updateProcurementProcess, { isSuccess, isLoading: isSubmitting }] = usePutProcurementProcessesByIdMutation();
  const { data: user } = useGetUsersMeQuery();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const [showQuotationForm, setShowQuotationForm] = useState(false);

  const developmentId = procurementProcess?.requisition?.development.id;
  let procurementProcessPartial: any
  const { data: development } = useGetDevelopmentsByIdQuery(
    { id: developmentId! },
    { skip: !developmentId }
  );

  const [openDialog, setOpenDialog] = useState<string | null>(null);
  const [selectedSupplier, setSelectedSupplier] = useState<number | null>(null);

  const handleDialogOpen = (url: string) => setOpenDialog(url);
  const handleDialogClose = () => setOpenDialog(null);
  useEffect(() => {
    setShowOverlay(isSubmitting || isSuccess);
    if (isSuccess) {
      setTimeout(() => {
        setShowOverlay(false);
        setShowSuccessMsg(true);
        setRedirectToDashboard(true);
      }, 3000);
    }
  }, [isSubmitting, isSuccess]);





  const handleSelectSupplier = async (index: number, quotation: any) => {
    const newSelection = selectedSupplier === index ? null : index;
    setSelectedSupplier(newSelection);

    if (newSelection !== null && procurementProcess?.currentTask === "CPOPicksSupplier") {
      const updatedQuotations = procurementProcess?.pcReview?.quotations?.map((q) => {
        if (q.supplier === quotation.supplier) {
          return { ...q, selected: true };
        }
        return q;
      });

      if (updatedQuotations) {
        updatedQuotationsAfterSelection = updatedQuotations;
        console.log("++++++++++updatedQuotationsAfterSelection+++++++", updatedQuotationsAfterSelection)
      }



    }
  };


  const initialValues: RequisitionReviewType = {
    ...createRequisitionReviewModel.initialValues,
    quotations: [{
      supplier: { id: '', name: '' }, attachments: [], paymentTerms: '', remarks: '', selected: false,totalb4VAT:0,totalVAT:0,totalDiscount:0 ,grandTotal:0,quotationItems: [{
        unit: '',
        unitName: '',
        description: '',
        quantity: {
          quantityValue: 0,
          quantityUnit: ''
        },
        rate: 0,
        totalCostPerItem: 0,
        VAT:0,
        discountPerItem:0
      }]
    }],
  };

  const handleSubmit = async (values: RequisitionReviewType) => {

    const reviewData: RequisitionReviewType = {
      comment: values.comment,
      approved: values.approved,
      user: user,
      quotations: values.quotations,
      dateReviewed: new Date()
    };


    
    procurementProcessPartial = {
      initiatedDate: procurementProcess?.initiatedDate,
      lastActionedDate: new Date(),
      status: procurementProcess?.status,
      siteManagerReview: {
        comment: procurementProcess?.siteManagerReview?.comment,
        approved: procurementProcess?.siteManagerReview?.approved,
        user: {
          ...procurementProcess?.siteManagerReview?.user,
          previousToken: '',
        },
        dateReviewed: procurementProcess?.siteManagerReview?.dateReviewed,
      },


      initiatedByUser: {
        ...procurementProcess?.initiatedByUser,
        previousToken: '',
      },
      requisition: {
        ...procurementProcess?.requisition,
        id: '',
        project: procurementProcess?.requisition.project,
        development: procurementProcess?.requisition.development,
        totalCost: procurementProcess?.requisition.totalCost,
        items: procurementProcess?.requisition?.items?.map((item: RequisitionItem) => ({
          ...item,
          quantity: {
            ...item.quantity,
            id: undefined,
          },
          unit: {
            id: item.unit?.id,
            name: item.unit?.name,
          },
          id: undefined,
        })),
      },
    };

    if (procurementProcess?.projectManagerReview) {
      procurementProcessPartial["projectManagerReview"] = {
        comment: procurementProcess?.projectManagerReview?.comment,
        approved: procurementProcess?.projectManagerReview?.approved,
        dateReviewed: procurementProcess?.projectManagerReview?.dateReviewed,
        user: {
          ...procurementProcess?.projectManagerReview?.user,
          previousToken: '',
        },
      };
    }

    if (procurementProcess?.cpoReview) {
      procurementProcessPartial["cpoReview"] = {
        comment: procurementProcess?.cpoReview?.comment,
        approved: procurementProcess?.cpoReview?.approved,
        dateReviewed: procurementProcess?.cpoReview?.dateReviewed,
        user: {
          ...procurementProcess?.cpoReview?.user,
          previousToken: '',
        },
      };
    }

    if (procurementProcess?.pmReview) {
      procurementProcessPartial["pmReview"] = {
        comment: procurementProcess?.pmReview?.comment,
        approved: procurementProcess?.pmReview?.approved,
        dateReviewed: procurementProcess?.pmReview?.dateReviewed,
        user: {
          ...procurementProcess?.pmReview?.user,
          previousToken: '',
        },
      };
    }
    // if (procurementProcess?.pcReview) {
    //   procurementProcessPartial["pcReview"] = {
    //     comment: procurementProcess?.pcReview?.comment,
    //     approved: procurementProcess?.pcReview?.approved,
    //     dateReviewed: procurementProcess?.pcReview?.dateReviewed,
    //     user: {
    //       ...procurementProcess?.pcReview?.user,
    //       previousToken: '',
    //     },

    //   };
    // }

    if (procurementProcess?.pcReview) {
      procurementProcessPartial["pcReview"] = {
        comment: procurementProcess?.pcReview?.comment,
        approved: procurementProcess?.pcReview?.approved,
        dateReviewed: procurementProcess?.pcReview?.dateReviewed,
        user: {
          ...procurementProcess?.pcReview?.user,
          previousToken: '',
        },
        quotations: procurementProcess?.pcReview?.quotations?.map((quotation) => ({
          paymentTerms: quotation.paymentTerms,
          remarks: quotation.remarks,
          supplier: quotation.supplier,
          attachments: quotation.attachments?.map((attachment) => ({
            name: attachment.name,
            url: attachment.url,
          })),
        })),
      };

    }


    if (!values.approved) {
      procurementProcessPartial.currentTask = "CorrectPurchaseRequisition";
      procurementProcessPartial.currentTaskOwner = "site-agent";
      procurementProcessPartial.previousTask = "SiteManagerReviewPurchaseRequisition";
      if (procurementProcess?.currentTask === "SiteManagerReviewPurchaseRequisition") {
        procurementProcessPartial["siteManagerReview"] = reviewData;
      } else if (procurementProcess?.currentTask === "ProjectManagerReviewPurchaseRequisition") {
        procurementProcessPartial["projectManagerReview"] = reviewData;
        procurementProcessPartial.previousTask = "ProjectManagerReviewPurchaseRequisition";
      } else if (procurementProcess?.currentTask === "PCToUploadQuotations") {
        procurementProcessPartial["pcReview"] = reviewData;
        procurementProcessPartial.previousTask = "PCToUploadQuotations";
        procurementProcessPartial.currentTaskOwner = "site-agent";
      }
      else if (procurementProcess?.currentTask === "PMToReviewProcurementApplication") {
        procurementProcessPartial["pmReview"] = reviewData;
        procurementProcessPartial.currentTask = "PCToUploadQuotations";
        procurementProcessPartial.currentTaskOwner = "procurement-clerk";
        procurementProcessPartial.previousTask = "PMToReviewProcurementApplication";
      }
      else if (procurementProcess?.currentTask === "CPOPicksSupplier") {
        procurementProcessPartial["cpoReview"] = reviewData;
        procurementProcessPartial.currentTask = "PCToUploadQuotations";
        procurementProcessPartial.currentTaskOwner = "procurement-clerk";
        procurementProcessPartial.previousTask = "CPOPicksSupplier";
      }
      else if (procurementProcess?.currentTask === "ProChairReviewsSupplierChoice") {
        procurementProcessPartial["proChairReview"] = reviewData;
        procurementProcessPartial.previousTask = "ProChairReviewsSupplierChoice";
        procurementProcessPartial.currentTask = "CPOPicksSupplier";
        procurementProcessPartial.currentTaskOwner = "cpo";
      }
      console.log("procurementProcessPartial", procurementProcessPartial)
    }

    if (values.approved && procurementProcess?.currentTask === "SiteManagerReviewPurchaseRequisition") {
      procurementProcessPartial["siteManagerReview"] = reviewData;
      procurementProcessPartial.currentTask = "ProjectManagerReviewPurchaseRequisition";
      procurementProcessPartial.currentTaskOwner = "project-manager";
      procurementProcessPartial.previousTask = "SiteManagerReviewPurchaseRequisition";
      procurementProcessPartial["status"] = "New Requisition"
    } else if (values.approved && procurementProcess?.currentTask === "ProjectManagerReviewPurchaseRequisition") {

      procurementProcessPartial.currentTask = "PCToUploadQuotations";
      procurementProcessPartial.currentTaskOwner = "procurement-clerk";
      procurementProcessPartial.previousTask = "ProjectManagerReviewPurchaseRequisition";
      procurementProcessPartial["projectManagerReview"] = reviewData;
      console.log("<<<<<<------------reviewData------->",reviewData);
      procurementProcessPartial.requisition.approved = true;


    }

    // if (values.approved && procurementProcess?.currentTask === "PCToUploadQuotations") {
    //   procurementProcessPartial["status"]="New Requisition"
    //   procurementProcessPartial.currentTask = "PMToReviewProcurementApplication";
    //   procurementProcessPartial.currentTaskOwner = "procurement-manager";
    //   procurementProcessPartial.previousTask = "PCToUploadQuotations";
    //   procurementProcessPartial["pcReview"] = reviewData;


    // }

    if (values.approved && procurementProcess?.currentTask === "PCToUploadQuotations") {
      procurementProcessPartial["status"] = "New Purchase Order"
      procurementProcessPartial.currentTask = "CPOPicksSupplier";
      procurementProcessPartial.currentTaskOwner = "cpo";
      procurementProcessPartial.previousTask = "PMToReviewProcurementApplication";
      procurementProcessPartial["pcReview"] = reviewData;

    }

    if (values.approved && procurementProcess?.currentTask === "CPOPicksSupplier") {

      procurementProcessPartial.currentTask = "ProChairReviewsSupplierChoice";
      procurementProcessPartial.currentTaskOwner = "pro-chair";
      procurementProcessPartial.previousTask = "CPOPicksSupplier";
      if (updatedQuotationsAfterSelection) {
        procurementProcessPartial.pcReview.quotations = updatedQuotationsAfterSelection;
      }

      if (updatedQuotationsAfterSelection) {
        procurementProcessPartial.pcReview.quotations = updatedQuotationsAfterSelection.map(quotation => ({
          ...quotation,
          selected: !!quotation.selected
        }));
      }
      procurementProcessPartial["cpoReview"] = reviewData;


    }

    if (values.approved && procurementProcess?.currentTask === "ProChairReviewsSupplierChoice") {
      // procurementProcessPartial.currentTask = "PMToIssuePurchaseOrder";
      procurementProcessPartial.currentTask = "StoresClerkToReceiveGoods";
      procurementProcessPartial.currentTaskOwner = "stores-clerk";
      procurementProcessPartial.previousTask = "ProChairReviewsSupplierChoice"
      procurementProcessPartial["proChairReview"] = reviewData;
      procurementProcessPartial.pcReview = procurementProcess.pcReview;
    }

    
    // if (values.approved && procurementProcess?.currentTask === "PMToIssuePurchaseOrder") {
    //   procurementProcessPartial.status = "GRV"
    //   procurementProcessPartial.currentTask = "StoresClerkToReceiveGoods";
    //   procurementProcessPartial.currentTaskOwner = "stores-clerk";
    //   procurementProcessPartial.previousTask = "PMToIssuePurchaseOrder";
    //   procurementProcessPartial["pmReview"] = reviewData;
    //   procurementProcessPartial.pcReview = procurementProcess.pcReview;
    // }

    console.log("Form data ", procurementProcessPartial)

    setShowOverlay(true);
    try {
      const result = await updateProcurementProcess({
        id: match.params.uuid,
        procurementProcessPartial
      }).unwrap();
      setShowOverlay(false);
      if (result) {
        console.log('success');
        toast.success('Task completed successfully!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setShowOverlay(false);
      console.error("Completing Task Failed:", error);
      toast.error('Completing Task Failed.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  if (redirectToDashboard) {
    return <ProcurementProcessDashboard />;
  }

  if (procurementProcess?.requisition && (!isLoading || !isError)) {
    return (
      <>

        <PageWithHeader>

          {/* {procurementProcess.requisition?.approved === false && (
            <Chip
              label={
                procurementProcess.cpoReview?.approved === true
                  ? 'REQUISITION APPROVED'
                  : 'REQUISITION NOT YET APPROVED'
              }
              color={
                procurementProcess.cpoReview?.approved === true
                  ? 'success'
                  : 'error'
              }
              sx={{
                fontWeight: 'bold',
                marginTop: '16px',
                marginBottom: '8px',
                width: '100%',
                textAlign: 'center',
              }}
            />
          )} */}

          {/* {procurementProcess.requisition?.approved === true && (
            <Chip
              label={
                procurementProcess.requisition?.approved === true
                  ? 'CREATED PURCHASE ORDER'
                  : 'PURCHASE ORDER NOT YET CREATED'
              }
              color={
                procurementProcess.requisition?.approved === true
                  ? 'success'
                  : 'error'
              }
              sx={{
                fontWeight: 'bold',
                marginTop: '16px',
                marginBottom: '8px',
                width: '100%',
                textAlign: 'center',
              }}
            />
          )} */}



          <RenderRequisitionHeader development={development || 'Development'} />
          {development && (
            <RenderRequisitionDetails
              procurementProcess={procurementProcess}
              development={development}
              selectedSupplier={selectedSupplier}
              openDialog={openDialog}
              handleDialogOpen={handleDialogOpen}
              handleDialogClose={handleDialogClose}
              handleSelectSupplier={handleSelectSupplier}
              showQuotationForm={showQuotationForm}
              setShowQuotationForm={setShowQuotationForm}
            />
          )}

          {procurementProcess?.currentTask === "PCToUploadQuotations" && (
            <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowQuotationForm(!showQuotationForm)}
              >
                {showQuotationForm ? "Request Requisition Amendments" : "Proceed with Quotations Upload"}
              </Button>
            </Stack>
          )}

          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <QuotationForm
                procurementProcess={procurementProcess}
                initialValues={initialValues}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                values={values}
                showQuotationForm={showQuotationForm}
                setShowQuotationForm={setShowQuotationForm}
              />
            )}
          </Formik>

          <div>
            {/* <Chip
              label={
                procurementProcess.cpoReview?.approved === true
                  ? 'REQUISITION APPROVED'
                  : 'REQUISITION NOT YET APPROVED'
              }
              color={
                procurementProcess.cpoReview?.approved === true
                  ? 'success'
                  : 'error'
              }
              style={{ fontWeight: 'bold' }}
            /> */}
          </div>

        </PageWithHeader>

        {showOverlay && <LoadingCover isSuccess={isSuccess} />}
        {showSuccessMsg && (
          <Stack>
            <Typography>Thank you, your response has been received</Typography>
            <Button onClick={() => window.location.reload()} variant="text" sx={{ maxWidth: '200px' }}>Create Another</Button>
          </Stack>
        )}
      </>
    );
  } else return <></>;

};

export default ViewRequisition;
