import React from 'react';
import { Autocomplete, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Field, FieldArray, useFormikContext, FieldProps } from 'formik';
import { Delete as DeleteIcon } from '@mui/icons-material';
import ItemCategoryFormModel from '../models/createItemCategoryModel';


const {
    formField: {       
        categoryCode,
        description,
    },
} = ItemCategoryFormModel;

interface OwnProps {
    editing?: boolean;
}

const CreateItemCategoryForm: React.FC<OwnProps> = (props) => {
    const { editing = false } = props;
    const { errors, touched, values, setFieldValue } = useFormikContext<any>();
   
   
    return (
        <Stack width="100%" spacing={5}>
            <Typography variant='h4' gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
                {editing ? 'Edit' : 'Add'} Item Category
            </Typography>
            <Stack direction={'row'} spacing={2}>
                <Field
                    as={TextField}
                    fullWidth
                    {...categoryCode}
                    error={touched[categoryCode.name] && Boolean(errors[categoryCode.name])}
                    helperText={touched[categoryCode.name] && errors[categoryCode.name] ? errors[categoryCode.name] : ''}
                />
                

                <Field
                    as={TextField}
                    fullWidth
                    {...description}
                    error={touched[description.name] && Boolean(errors[description.name])}
                    helperText={touched[description.name] && errors[description.name] ? errors[description.name] : ''}
                />
            </Stack>


        </Stack>
    );
};

export default CreateItemCategoryForm;
