import React, { useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ItemCategory, useGetItemCategoriesQuery } from '../../../redux/vmsApi';

const ItemCategoriesList: React.FC = () => {
  const { data: itemCategories, isLoading, isError } = useGetItemCategoriesQuery({});
  const [selecteditemCategory, setSelecteditemCategory] = useState<ItemCategory | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleEditClick = (itemCategory: ItemCategory) => {
    setSelecteditemCategory(itemCategory);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelecteditemCategory(null);
  };


  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error fetching itemCategorys.</div>;
  }

  return (
    <Box p={10}>
      <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        View Item Categories
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category Code</TableCell>
              <TableCell>Description</TableCell>
             
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemCategories?.map((itemCategory: ItemCategory) => (
              <TableRow key={itemCategory.id}>
                <TableCell>{itemCategory.categoryCode}</TableCell>
                <TableCell>{itemCategory.description}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEditClick(itemCategory)}>Edit</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
     
    </Box>
  );
};

export default ItemCategoriesList;
