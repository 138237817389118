import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ItemsPage from './pages/ItemsPage';
import CreateInventoryPage from './component/CreateInventoryComponent';
import InventoryPage from './pages/InventoryPage';
import InventoryLocationPage from './pages/inventoryLocationPage';
import ItemCategoriesPage from './pages/itemsCategoryPage';
import ItemsLocationPage from './pages/itemsLocationPage';
import ItemTransfersPage from './pages/itemTransferPage';
import ItemsIssuedPage from './pages/itemsIssuedPage';
import BackOrdersPage from './pages/backOrdersPage';

const InventoryRoutes = () => {
  return (
    <Switch>
      <Route exact path="/inventory/locations-stock" component={ItemsLocationPage} />
      <Route exact path="/inventory/create-inventory" component={InventoryPage} />
      <Route exact path="/inventory/items" component={ItemsPage} />
      <Route exact path="/inventory/inventory-locations" component={InventoryLocationPage} />
      <Route exact path="/inventory/item-categories" component={ItemCategoriesPage} />
      <Route exact path="/inventory/item-transfers" component={ItemTransfersPage} />
      <Route exact path="/inventory/items-issued" component={ItemsIssuedPage} />
      <Route exact path="/inventory/back-orders" component={BackOrdersPage} />
    </Switch>
  );
}

export default InventoryRoutes;
