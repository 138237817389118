import { emptySplitApi as api } from "./baseApi";
export const addTagTypes = [
  "EmployeeReviewController",
  "InventoryLocationController",
  "AccessApplicationController",
  "AccessLogController",
  "InviteController",
  "BackOrderController",
  "BomController",
  "PropertyRegisterController",
  "AgreementController",
  "ProcurementProcessController",
  "PurchaseOrderController",
  "ProcurementController",
  "RequisitionReviewController",
  "DevelopmentController",
  "InventoryController",
  "ItemCategoryController",
  "ItemLocationController",
  "ItemTransferController",
  "ItemController",
  "ItemIssuedController",
  "PasswordResetRequestController",
  "PayrollController",
  "PingController",
  "StakeholderController",
  "SupervisorController",
  "UnitController",
  "UserController",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      get360ReviewsByUserByUserId: build.query<
        Get360ReviewsByUserByUserIdApiResponse,
        Get360ReviewsByUserByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/360-reviews-by-user/${queryArg.userId}`,
        }),
        providesTags: ["EmployeeReviewController"],
      }),
      getInventoryLocationsCount: build.query<
        GetInventoryLocationsCountApiResponse,
        GetInventoryLocationsCountApiArg
      >({
        query: (queryArg) => ({
          url: `/InventoryLocations/count`,
          params: { where: queryArg.where },
        }),
        providesTags: ["InventoryLocationController"],
      }),
      getInventoryLocationsById: build.query<
        GetInventoryLocationsByIdApiResponse,
        GetInventoryLocationsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/InventoryLocations/${queryArg.id}` }),
        providesTags: ["InventoryLocationController"],
      }),
      deleteInventoryLocationsById: build.mutation<
        DeleteInventoryLocationsByIdApiResponse,
        DeleteInventoryLocationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/InventoryLocations/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["InventoryLocationController"],
      }),
      postInventoryLocations: build.mutation<
        PostInventoryLocationsApiResponse,
        PostInventoryLocationsApiArg
      >({
        query: (queryArg) => ({
          url: `/InventoryLocations`,
          method: "POST",
          body: queryArg.newInventoryLocation,
        }),
        invalidatesTags: ["InventoryLocationController"],
      }),
      getInventoryLocations: build.query<
        GetInventoryLocationsApiResponse,
        GetInventoryLocationsApiArg
      >({
        query: (queryArg) => ({
          url: `/InventoryLocations`,
          params: { filter: queryArg.filter },
        }),
        providesTags: ["InventoryLocationController"],
      }),
      getAccessApplicationsCount: build.query<
        GetAccessApplicationsCountApiResponse,
        GetAccessApplicationsCountApiArg
      >({
        query: (queryArg) => ({
          url: `/access-applications/count`,
          params: { where: queryArg.where },
        }),
        providesTags: ["AccessApplicationController"],
      }),
      putAccessApplicationsById: build.mutation<
        PutAccessApplicationsByIdApiResponse,
        PutAccessApplicationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/access-applications/${queryArg.id}`,
          method: "PUT",
          body: queryArg.updateAccessApplication,
        }),
        invalidatesTags: ["AccessApplicationController"],
      }),
      getAccessApplicationsById: build.query<
        GetAccessApplicationsByIdApiResponse,
        GetAccessApplicationsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/access-applications/${queryArg.id}` }),
        providesTags: ["AccessApplicationController"],
      }),
      deleteAccessApplicationsById: build.mutation<
        DeleteAccessApplicationsByIdApiResponse,
        DeleteAccessApplicationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/access-applications/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["AccessApplicationController"],
      }),
      postAccessApplications: build.mutation<
        PostAccessApplicationsApiResponse,
        PostAccessApplicationsApiArg
      >({
        query: (queryArg) => ({
          url: `/access-applications`,
          method: "POST",
          body: queryArg.newAccessApplication,
        }),
        invalidatesTags: ["AccessApplicationController"],
      }),
      getAccessApplications: build.query<
        GetAccessApplicationsApiResponse,
        GetAccessApplicationsApiArg
      >({
        query: (queryArg) => ({
          url: `/access-applications`,
          params: { filter: queryArg.filter },
        }),
        providesTags: ["AccessApplicationController"],
      }),
      getAccessLogsCount: build.query<
        GetAccessLogsCountApiResponse,
        GetAccessLogsCountApiArg
      >({
        query: (queryArg) => ({
          url: `/access-logs/count`,
          params: { where: queryArg.where },
        }),
        providesTags: ["AccessLogController"],
      }),
      getAccessLogsById: build.query<
        GetAccessLogsByIdApiResponse,
        GetAccessLogsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/access-logs/${queryArg.id}` }),
        providesTags: ["AccessLogController"],
      }),
      deleteAccessLogsById: build.mutation<
        DeleteAccessLogsByIdApiResponse,
        DeleteAccessLogsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/access-logs/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["AccessLogController"],
      }),
      postAccessLogs: build.mutation<
        PostAccessLogsApiResponse,
        PostAccessLogsApiArg
      >({
        query: (queryArg) => ({
          url: `/access-logs`,
          method: "POST",
          body: queryArg.newAccessLog,
        }),
        invalidatesTags: ["AccessLogController"],
      }),
      getAccessLogs: build.query<GetAccessLogsApiResponse, GetAccessLogsApiArg>(
        {
          query: (queryArg) => ({
            url: `/access-logs`,
            params: { filter: queryArg.filter },
          }),
          providesTags: ["AccessLogController"],
        }
      ),
      postAddEmployeeReview: build.mutation<
        PostAddEmployeeReviewApiResponse,
        PostAddEmployeeReviewApiArg
      >({
        query: (queryArg) => ({
          url: `/add-employee-review`,
          method: "POST",
          body: queryArg.employeeReviewData,
        }),
        invalidatesTags: ["EmployeeReviewController"],
      }),
      postAddInvite: build.mutation<
        PostAddInviteApiResponse,
        PostAddInviteApiArg
      >({
        query: (queryArg) => ({
          url: `/add-invite`,
          method: "POST",
          body: queryArg.inviteData,
        }),
        invalidatesTags: ["InviteController"],
      }),
      postAddLifeWheel: build.mutation<
        PostAddLifeWheelApiResponse,
        PostAddLifeWheelApiArg
      >({
        query: (queryArg) => ({
          url: `/add-life-wheel`,
          method: "POST",
          body: queryArg.lifeWheelData,
        }),
        invalidatesTags: ["EmployeeReviewController"],
      }),
      postBackOrders: build.mutation<
        PostBackOrdersApiResponse,
        PostBackOrdersApiArg
      >({
        query: (queryArg) => ({
          url: `/back-orders`,
          method: "POST",
          body: queryArg.newBackOrder,
        }),
        invalidatesTags: ["BackOrderController"],
      }),
      getBackOrders: build.query<GetBackOrdersApiResponse, GetBackOrdersApiArg>(
        {
          query: (queryArg) => ({
            url: `/back-orders`,
            params: { filter: queryArg.filter },
          }),
          providesTags: ["BackOrderController"],
        }
      ),
      patchBomById: build.mutation<PatchBomByIdApiResponse, PatchBomByIdApiArg>(
        {
          query: (queryArg) => ({
            url: `/bom/${queryArg.id}`,
            method: "PATCH",
            body: queryArg.billOfMaterialPartial,
          }),
          invalidatesTags: ["BomController"],
        }
      ),
      getBomById: build.query<GetBomByIdApiResponse, GetBomByIdApiArg>({
        query: (queryArg) => ({ url: `/bom/${queryArg.id}` }),
        providesTags: ["BomController"],
      }),
      deleteBomById: build.mutation<
        DeleteBomByIdApiResponse,
        DeleteBomByIdApiArg
      >({
        query: (queryArg) => ({ url: `/bom/${queryArg.id}`, method: "DELETE" }),
        invalidatesTags: ["BomController"],
      }),
      postBom: build.mutation<PostBomApiResponse, PostBomApiArg>({
        query: (queryArg) => ({
          url: `/bom`,
          method: "POST",
          body: queryArg.bomData,
        }),
        invalidatesTags: ["BomController"],
      }),
      getBomsCount: build.query<GetBomsCountApiResponse, GetBomsCountApiArg>({
        query: (queryArg) => ({
          url: `/boms/count`,
          params: { where: queryArg.where },
        }),
        providesTags: ["BomController"],
      }),
      getBomsByUnitId: build.query<
        GetBomsByUnitIdApiResponse,
        GetBomsByUnitIdApiArg
      >({
        query: (queryArg) => ({ url: `/boms/${queryArg.unitId}` }),
        providesTags: ["BomController"],
      }),
      getBoms: build.query<GetBomsApiResponse, GetBomsApiArg>({
        query: (queryArg) => ({
          url: `/boms`,
          params: { filter: queryArg.filter },
        }),
        providesTags: ["BomController"],
      }),
      postClient: build.mutation<PostClientApiResponse, PostClientApiArg>({
        query: (queryArg) => ({
          url: `/client`,
          method: "POST",
          body: queryArg.clientData,
        }),
        invalidatesTags: ["PropertyRegisterController"],
      }),
      getClients: build.query<GetClientsApiResponse, GetClientsApiArg>({
        query: (queryArg) => ({
          url: `/clients`,
          params: { filter: queryArg.filter },
        }),
        providesTags: ["PropertyRegisterController"],
      }),
      postCreateAgreement: build.mutation<
        PostCreateAgreementApiResponse,
        PostCreateAgreementApiArg
      >({
        query: (queryArg) => ({
          url: `/create-agreement`,
          method: "POST",
          body: queryArg.agreementData,
        }),
        invalidatesTags: ["AgreementController"],
      }),
      postCreateProcurementProcess: build.mutation<
        PostCreateProcurementProcessApiResponse,
        PostCreateProcurementProcessApiArg
      >({
        query: (queryArg) => ({
          url: `/create-procurement-process`,
          method: "POST",
          body: queryArg.newProcurementProcess,
        }),
        invalidatesTags: ["ProcurementProcessController"],
      }),
      postCreatePurchaseOrder: build.mutation<
        PostCreatePurchaseOrderApiResponse,
        PostCreatePurchaseOrderApiArg
      >({
        query: (queryArg) => ({
          url: `/create-purchase-order`,
          method: "POST",
          body: queryArg.purchaseOrderData,
        }),
        invalidatesTags: ["PurchaseOrderController"],
      }),
      postCreateRequisition: build.mutation<
        PostCreateRequisitionApiResponse,
        PostCreateRequisitionApiArg
      >({
        query: (queryArg) => ({
          url: `/create-requisition`,
          method: "POST",
          body: queryArg.requisitionData,
        }),
        invalidatesTags: ["ProcurementController"],
      }),
      postCreateRequisitionReview: build.mutation<
        PostCreateRequisitionReviewApiResponse,
        PostCreateRequisitionReviewApiArg
      >({
        query: (queryArg) => ({
          url: `/create-requisition-review`,
          method: "POST",
          body: queryArg.requisitionReviewData,
        }),
        invalidatesTags: ["RequisitionReviewController"],
      }),
      postDevelopment: build.mutation<
        PostDevelopmentApiResponse,
        PostDevelopmentApiArg
      >({
        query: (queryArg) => ({
          url: `/development`,
          method: "POST",
          body: queryArg.developmentData,
        }),
        invalidatesTags: ["DevelopmentController"],
      }),
      getDevelopmentsCount: build.query<
        GetDevelopmentsCountApiResponse,
        GetDevelopmentsCountApiArg
      >({
        query: (queryArg) => ({
          url: `/developments/count`,
          params: { where: queryArg.where },
        }),
        providesTags: ["DevelopmentController"],
      }),
      getDevelopmentsProjects: build.query<
        GetDevelopmentsProjectsApiResponse,
        GetDevelopmentsProjectsApiArg
      >({
        query: () => ({ url: `/developments/projects` }),
        providesTags: ["DevelopmentController"],
      }),
      putDevelopmentsById: build.mutation<
        PutDevelopmentsByIdApiResponse,
        PutDevelopmentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/developments/${queryArg.id}`,
          method: "PUT",
          body: queryArg.development,
        }),
        invalidatesTags: ["DevelopmentController"],
      }),
      patchDevelopmentsById: build.mutation<
        PatchDevelopmentsByIdApiResponse,
        PatchDevelopmentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/developments/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.developmentPartial,
        }),
        invalidatesTags: ["DevelopmentController"],
      }),
      getDevelopmentsById: build.query<
        GetDevelopmentsByIdApiResponse,
        GetDevelopmentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/developments/${queryArg.id}`,
          params: { filter: queryArg.filter },
        }),
        providesTags: ["DevelopmentController"],
      }),
      deleteDevelopmentsById: build.mutation<
        DeleteDevelopmentsByIdApiResponse,
        DeleteDevelopmentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/developments/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["DevelopmentController"],
      }),
      patchDevelopments: build.mutation<
        PatchDevelopmentsApiResponse,
        PatchDevelopmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/developments`,
          method: "PATCH",
          body: queryArg.developmentPartial,
          params: { where: queryArg.where },
        }),
        invalidatesTags: ["DevelopmentController"],
      }),
      getDevelopments: build.query<
        GetDevelopmentsApiResponse,
        GetDevelopmentsApiArg
      >({
        query: () => ({ url: `/developments` }),
        providesTags: ["DevelopmentController"],
      }),
      patchEmployeeReviewById: build.mutation<
        PatchEmployeeReviewByIdApiResponse,
        PatchEmployeeReviewByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/employee-review/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.employeeReviewPartial,
        }),
        invalidatesTags: ["EmployeeReviewController"],
      }),
      getEmployeeReviewById: build.query<
        GetEmployeeReviewByIdApiResponse,
        GetEmployeeReviewByIdApiArg
      >({
        query: (queryArg) => ({ url: `/employee-review/${queryArg.id}` }),
        providesTags: ["EmployeeReviewController"],
      }),
      getEmployeeReviewsByUserByUserId: build.query<
        GetEmployeeReviewsByUserByUserIdApiResponse,
        GetEmployeeReviewsByUserByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/employee-reviews-by-user/${queryArg.userId}`,
        }),
        providesTags: ["EmployeeReviewController"],
      }),
      getFeedbackSurveyById: build.query<
        GetFeedbackSurveyByIdApiResponse,
        GetFeedbackSurveyByIdApiArg
      >({
        query: (queryArg) => ({ url: `/feedback-survey/${queryArg.id}` }),
        providesTags: ["EmployeeReviewController"],
      }),
      postFeedbackSurvey: build.mutation<
        PostFeedbackSurveyApiResponse,
        PostFeedbackSurveyApiArg
      >({
        query: (queryArg) => ({
          url: `/feedback-survey`,
          method: "POST",
          body: queryArg.feedbackSurveyData,
        }),
        invalidatesTags: ["EmployeeReviewController"],
      }),
      postGetSignedUrl: build.mutation<
        PostGetSignedUrlApiResponse,
        PostGetSignedUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/get-signed-url`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["ProcurementProcessController"],
      }),
      getInventoriesCount: build.query<
        GetInventoriesCountApiResponse,
        GetInventoriesCountApiArg
      >({
        query: (queryArg) => ({
          url: `/inventories/count`,
          params: { where: queryArg.where },
        }),
        providesTags: ["InventoryController"],
      }),
      getInventoriesById: build.query<
        GetInventoriesByIdApiResponse,
        GetInventoriesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/inventories/${queryArg.id}` }),
        providesTags: ["InventoryController"],
      }),
      deleteInventoriesById: build.mutation<
        DeleteInventoriesByIdApiResponse,
        DeleteInventoriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/inventories/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["InventoryController"],
      }),
      postInventories: build.mutation<
        PostInventoriesApiResponse,
        PostInventoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/inventories`,
          method: "POST",
          body: queryArg.newInventory,
        }),
        invalidatesTags: ["InventoryController"],
      }),
      getInventories: build.query<
        GetInventoriesApiResponse,
        GetInventoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/inventories`,
          params: { filter: queryArg.filter },
        }),
        providesTags: ["InventoryController"],
      }),
      patchInviteMarkAsScannedById: build.mutation<
        PatchInviteMarkAsScannedByIdApiResponse,
        PatchInviteMarkAsScannedByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/invite/markAsScanned/${queryArg.id}`,
          method: "PATCH",
        }),
        invalidatesTags: ["InviteController"],
      }),
      getInviteById: build.query<GetInviteByIdApiResponse, GetInviteByIdApiArg>(
        {
          query: (queryArg) => ({ url: `/invite/${queryArg.id}` }),
          providesTags: ["InviteController"],
        }
      ),
      getInvites: build.query<GetInvitesApiResponse, GetInvitesApiArg>({
        query: (queryArg) => ({
          url: `/invites`,
          params: { timeZone: queryArg.timeZone },
        }),
        providesTags: ["InviteController"],
      }),
      getItemCategoriesCount: build.query<
        GetItemCategoriesCountApiResponse,
        GetItemCategoriesCountApiArg
      >({
        query: (queryArg) => ({
          url: `/item-categories/count`,
          params: { where: queryArg.where },
        }),
        providesTags: ["ItemCategoryController"],
      }),
      getItemCategoriesById: build.query<
        GetItemCategoriesByIdApiResponse,
        GetItemCategoriesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/item-categories/${queryArg.id}` }),
        providesTags: ["ItemCategoryController"],
      }),
      deleteItemCategoriesById: build.mutation<
        DeleteItemCategoriesByIdApiResponse,
        DeleteItemCategoriesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/item-categories/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["ItemCategoryController"],
      }),
      postItemCategories: build.mutation<
        PostItemCategoriesApiResponse,
        PostItemCategoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/item-categories`,
          method: "POST",
          body: queryArg.newItemCategory,
        }),
        invalidatesTags: ["ItemCategoryController"],
      }),
      getItemCategories: build.query<
        GetItemCategoriesApiResponse,
        GetItemCategoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/item-categories`,
          params: { filter: queryArg.filter },
        }),
        providesTags: ["ItemCategoryController"],
      }),
      getItemLocationsCount: build.query<
        GetItemLocationsCountApiResponse,
        GetItemLocationsCountApiArg
      >({
        query: (queryArg) => ({
          url: `/item-locations/count`,
          params: { where: queryArg.where },
        }),
        providesTags: ["ItemLocationController"],
      }),
      getItemLocationsById: build.query<
        GetItemLocationsByIdApiResponse,
        GetItemLocationsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/item-locations/${queryArg.id}` }),
        providesTags: ["ItemLocationController"],
      }),
      deleteItemLocationsById: build.mutation<
        DeleteItemLocationsByIdApiResponse,
        DeleteItemLocationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/item-locations/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["ItemLocationController"],
      }),
      postItemLocations: build.mutation<
        PostItemLocationsApiResponse,
        PostItemLocationsApiArg
      >({
        query: (queryArg) => ({
          url: `/item-locations`,
          method: "POST",
          body: queryArg.newItemLocation,
        }),
        invalidatesTags: ["ItemLocationController"],
      }),
      getItemLocations: build.query<
        GetItemLocationsApiResponse,
        GetItemLocationsApiArg
      >({
        query: (queryArg) => ({
          url: `/item-locations`,
          params: { filter: queryArg.filter },
        }),
        providesTags: ["ItemLocationController"],
      }),
      getItemTransfersCount: build.query<
        GetItemTransfersCountApiResponse,
        GetItemTransfersCountApiArg
      >({
        query: (queryArg) => ({
          url: `/item-transfers/count`,
          params: { where: queryArg.where },
        }),
        providesTags: ["ItemTransferController"],
      }),
      getItemTransfersById: build.query<
        GetItemTransfersByIdApiResponse,
        GetItemTransfersByIdApiArg
      >({
        query: (queryArg) => ({ url: `/item-transfers/${queryArg.id}` }),
        providesTags: ["ItemTransferController"],
      }),
      deleteItemTransfersById: build.mutation<
        DeleteItemTransfersByIdApiResponse,
        DeleteItemTransfersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/item-transfers/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["ItemTransferController"],
      }),
      postItemTransfers: build.mutation<
        PostItemTransfersApiResponse,
        PostItemTransfersApiArg
      >({
        query: (queryArg) => ({
          url: `/item-transfers`,
          method: "POST",
          body: queryArg.newItemTransfer,
        }),
        invalidatesTags: ["ItemTransferController"],
      }),
      getItemTransfers: build.query<
        GetItemTransfersApiResponse,
        GetItemTransfersApiArg
      >({
        query: (queryArg) => ({
          url: `/item-transfers`,
          params: {
            filter: queryArg.filter,
            limit: queryArg.limit,
            skip: queryArg.skip,
          },
        }),
        providesTags: ["ItemTransferController"],
      }),
      getItemsCount: build.query<GetItemsCountApiResponse, GetItemsCountApiArg>(
        {
          query: (queryArg) => ({
            url: `/items/count`,
            params: { where: queryArg.where },
          }),
          providesTags: ["ItemController"],
        }
      ),
      getItemsById: build.query<GetItemsByIdApiResponse, GetItemsByIdApiArg>({
        query: (queryArg) => ({ url: `/items/${queryArg.id}` }),
        providesTags: ["ItemController"],
      }),
      deleteItemsById: build.mutation<
        DeleteItemsByIdApiResponse,
        DeleteItemsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/items/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["ItemController"],
      }),
      postItems: build.mutation<PostItemsApiResponse, PostItemsApiArg>({
        query: (queryArg) => ({
          url: `/items`,
          method: "POST",
          body: queryArg.newItem,
        }),
        invalidatesTags: ["ItemController"],
      }),
      getItems: build.query<GetItemsApiResponse, GetItemsApiArg>({
        query: (queryArg) => ({
          url: `/items`,
          params: {
            filter: queryArg.filter,
            limit: queryArg.limit,
            skip: queryArg.skip,
          },
        }),
        providesTags: ["ItemController"],
      }),
      postItemsIssued: build.mutation<
        PostItemsIssuedApiResponse,
        PostItemsIssuedApiArg
      >({
        query: (queryArg) => ({
          url: `/items-issued`,
          method: "POST",
          body: queryArg.newItemsIssued,
        }),
        invalidatesTags: ["ItemIssuedController"],
      }),
      getItemsIssued: build.query<
        GetItemsIssuedApiResponse,
        GetItemsIssuedApiArg
      >({
        query: (queryArg) => ({
          url: `/items-issued`,
          params: { filter: queryArg.filter },
        }),
        providesTags: ["ItemIssuedController"],
      }),
      getLifeWheelById: build.query<
        GetLifeWheelByIdApiResponse,
        GetLifeWheelByIdApiArg
      >({
        query: (queryArg) => ({ url: `/life-wheel/${queryArg.id}` }),
        providesTags: ["EmployeeReviewController"],
      }),
      getLifeWheelsByUserByUserId: build.query<
        GetLifeWheelsByUserByUserIdApiResponse,
        GetLifeWheelsByUserByUserIdApiArg
      >({
        query: (queryArg) => ({
          url: `/life-wheels-by-user/${queryArg.userId}`,
        }),
        providesTags: ["EmployeeReviewController"],
      }),
      getPasswordResetIsResetLinkValidByResetId: build.query<
        GetPasswordResetIsResetLinkValidByResetIdApiResponse,
        GetPasswordResetIsResetLinkValidByResetIdApiArg
      >({
        query: (queryArg) => ({
          url: `/password-reset/is-reset-link-valid/${queryArg.resetId}`,
        }),
        providesTags: ["PasswordResetRequestController"],
      }),
      postPasswordResetRequestByUserEmail: build.mutation<
        PostPasswordResetRequestByUserEmailApiResponse,
        PostPasswordResetRequestByUserEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/password-reset/request/${queryArg.userEmail}`,
          method: "POST",
        }),
        invalidatesTags: ["PasswordResetRequestController"],
      }),
      postPasswordResetResetPassword: build.mutation<
        PostPasswordResetResetPasswordApiResponse,
        PostPasswordResetResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/password-reset/reset-password`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["PasswordResetRequestController"],
      }),
      postPayrollUpload: build.mutation<
        PostPayrollUploadApiResponse,
        PostPayrollUploadApiArg
      >({
        query: (queryArg) => ({
          url: `/payroll/upload`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["PayrollController"],
      }),
      getPing: build.query<GetPingApiResponse, GetPingApiArg>({
        query: () => ({ url: `/ping` }),
        providesTags: ["PingController"],
      }),
      putProcurementProcessesById: build.mutation<
        PutProcurementProcessesByIdApiResponse,
        PutProcurementProcessesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/procurement-processes/${queryArg.id}`,
          method: "PUT",
          body: queryArg.procurementProcessPartial,
        }),
        invalidatesTags: ["ProcurementProcessController"],
      }),
      patchProcurementProcessesById: build.mutation<
        PatchProcurementProcessesByIdApiResponse,
        PatchProcurementProcessesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/procurement-processes/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.procurementProcessPartial,
        }),
        invalidatesTags: ["ProcurementProcessController"],
      }),
      getProcurementProcessesById: build.query<
        GetProcurementProcessesByIdApiResponse,
        GetProcurementProcessesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/procurement-processes/${queryArg.id}` }),
        providesTags: ["ProcurementProcessController"],
      }),
      deleteProcurementProcessesById: build.mutation<
        DeleteProcurementProcessesByIdApiResponse,
        DeleteProcurementProcessesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/procurement-processes/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["ProcurementProcessController"],
      }),
      getProcurementProcesses: build.query<
        GetProcurementProcessesApiResponse,
        GetProcurementProcessesApiArg
      >({
        query: (queryArg) => ({
          url: `/procurement-processes`,
          params: {
            currentTask: queryArg.currentTask,
            limit: queryArg.limit,
            skip: queryArg.skip,
          },
        }),
        providesTags: ["ProcurementProcessController"],
      }),
      getProperties: build.query<GetPropertiesApiResponse, GetPropertiesApiArg>(
        {
          query: (queryArg) => ({
            url: `/properties`,
            params: { filter: queryArg.filter },
          }),
          providesTags: ["PropertyRegisterController"],
        }
      ),
      postProperty: build.mutation<PostPropertyApiResponse, PostPropertyApiArg>(
        {
          query: (queryArg) => ({
            url: `/property`,
            method: "POST",
            body: queryArg.propertyData,
          }),
          invalidatesTags: ["PropertyRegisterController"],
        }
      ),
      getPurchaseOrderById: build.query<
        GetPurchaseOrderByIdApiResponse,
        GetPurchaseOrderByIdApiArg
      >({
        query: (queryArg) => ({ url: `/purchase-order/${queryArg.id}` }),
        providesTags: ["PurchaseOrderController"],
      }),
      getRequisitionById: build.query<
        GetRequisitionByIdApiResponse,
        GetRequisitionByIdApiArg
      >({
        query: (queryArg) => ({ url: `/requisition/${queryArg.id}` }),
        providesTags: ["ProcurementController"],
      }),
      getRequisitionReviewById: build.query<
        GetRequisitionReviewByIdApiResponse,
        GetRequisitionReviewByIdApiArg
      >({
        query: (queryArg) => ({ url: `/requisition-review/${queryArg.id}` }),
        providesTags: ["RequisitionReviewController"],
      }),
      getRequisitionReviews: build.query<
        GetRequisitionReviewsApiResponse,
        GetRequisitionReviewsApiArg
      >({
        query: () => ({ url: `/requisition-reviews` }),
        providesTags: ["RequisitionReviewController"],
      }),
      getRequisitionsNew: build.query<
        GetRequisitionsNewApiResponse,
        GetRequisitionsNewApiArg
      >({
        query: (queryArg) => ({
          url: `/requisitions/new`,
          params: { site: queryArg.site },
        }),
        providesTags: ["ProcurementController"],
      }),
      getRequisitions: build.query<
        GetRequisitionsApiResponse,
        GetRequisitionsApiArg
      >({
        query: () => ({ url: `/requisitions` }),
        providesTags: ["ProcurementController"],
      }),
      getStakeholdersByTypeByType: build.query<
        GetStakeholdersByTypeByTypeApiResponse,
        GetStakeholdersByTypeByTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/stakeholders/by-type/${queryArg["type"]}`,
        }),
        providesTags: ["StakeholderController"],
      }),
      getStakeholdersCount: build.query<
        GetStakeholdersCountApiResponse,
        GetStakeholdersCountApiArg
      >({
        query: (queryArg) => ({
          url: `/stakeholders/count`,
          params: { where: queryArg.where },
        }),
        providesTags: ["StakeholderController"],
      }),
      patchStakeholdersById: build.mutation<
        PatchStakeholdersByIdApiResponse,
        PatchStakeholdersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/stakeholders/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.stakeholderPartial,
        }),
        invalidatesTags: ["StakeholderController"],
      }),
      getStakeholdersById: build.query<
        GetStakeholdersByIdApiResponse,
        GetStakeholdersByIdApiArg
      >({
        query: (queryArg) => ({ url: `/stakeholders/${queryArg.id}` }),
        providesTags: ["StakeholderController"],
      }),
      deleteStakeholdersById: build.mutation<
        DeleteStakeholdersByIdApiResponse,
        DeleteStakeholdersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/stakeholders/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["StakeholderController"],
      }),
      postStakeholders: build.mutation<
        PostStakeholdersApiResponse,
        PostStakeholdersApiArg
      >({
        query: (queryArg) => ({
          url: `/stakeholders`,
          method: "POST",
          body: queryArg.newStakeholder,
        }),
        invalidatesTags: ["StakeholderController"],
      }),
      getStakeholders: build.query<
        GetStakeholdersApiResponse,
        GetStakeholdersApiArg
      >({
        query: (queryArg) => ({
          url: `/stakeholders`,
          params: { filter: queryArg.filter },
        }),
        providesTags: ["StakeholderController"],
      }),
      putSupervisorsById: build.mutation<
        PutSupervisorsByIdApiResponse,
        PutSupervisorsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/supervisors/${queryArg.id}`,
          method: "PUT",
          body: queryArg.supervisorPartial,
        }),
        invalidatesTags: ["SupervisorController"],
      }),
      patchSupervisorsById: build.mutation<
        PatchSupervisorsByIdApiResponse,
        PatchSupervisorsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/supervisors/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.supervisorPartial,
        }),
        invalidatesTags: ["SupervisorController"],
      }),
      deleteSupervisorsById: build.mutation<
        DeleteSupervisorsByIdApiResponse,
        DeleteSupervisorsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/supervisors/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SupervisorController"],
      }),
      postSupervisors: build.mutation<
        PostSupervisorsApiResponse,
        PostSupervisorsApiArg
      >({
        query: (queryArg) => ({
          url: `/supervisors`,
          method: "POST",
          body: queryArg.supervisorData,
        }),
        invalidatesTags: ["SupervisorController"],
      }),
      getSupervisors: build.query<
        GetSupervisorsApiResponse,
        GetSupervisorsApiArg
      >({
        query: (queryArg) => ({
          url: `/supervisors`,
          params: { filter: queryArg.filter },
        }),
        providesTags: ["SupervisorController"],
      }),
      postUnit: build.mutation<PostUnitApiResponse, PostUnitApiArg>({
        query: (queryArg) => ({
          url: `/unit`,
          method: "POST",
          body: queryArg.unitData,
        }),
        invalidatesTags: ["UnitController"],
      }),
      getUnitsByProjectByProjectId: build.query<
        GetUnitsByProjectByProjectIdApiResponse,
        GetUnitsByProjectByProjectIdApiArg
      >({
        query: (queryArg) => ({
          url: `/units/by-project/${queryArg.projectId}`,
          params: { filter: queryArg.filter },
        }),
        providesTags: ["UnitController"],
      }),
      getUnitsCount: build.query<GetUnitsCountApiResponse, GetUnitsCountApiArg>(
        {
          query: (queryArg) => ({
            url: `/units/count`,
            params: { where: queryArg.where },
          }),
          providesTags: ["UnitController"],
        }
      ),
      putUnitsById: build.mutation<PutUnitsByIdApiResponse, PutUnitsByIdApiArg>(
        {
          query: (queryArg) => ({
            url: `/units/${queryArg.id}`,
            method: "PUT",
            body: queryArg.unit,
          }),
          invalidatesTags: ["UnitController"],
        }
      ),
      patchUnitsById: build.mutation<
        PatchUnitsByIdApiResponse,
        PatchUnitsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/units/${queryArg.id}`,
          method: "PATCH",
          body: queryArg.unitPartial,
        }),
        invalidatesTags: ["UnitController"],
      }),
      getUnitsById: build.query<GetUnitsByIdApiResponse, GetUnitsByIdApiArg>({
        query: (queryArg) => ({
          url: `/units/${queryArg.id}`,
          params: { filter: queryArg.filter },
        }),
        providesTags: ["UnitController"],
      }),
      deleteUnitsById: build.mutation<
        DeleteUnitsByIdApiResponse,
        DeleteUnitsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/units/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["UnitController"],
      }),
      patchUnits: build.mutation<PatchUnitsApiResponse, PatchUnitsApiArg>({
        query: (queryArg) => ({
          url: `/units`,
          method: "PATCH",
          body: queryArg.unitPartial,
          params: { where: queryArg.where },
        }),
        invalidatesTags: ["UnitController"],
      }),
      getUnits: build.query<GetUnitsApiResponse, GetUnitsApiArg>({
        query: (queryArg) => ({
          url: `/units`,
          params: { filter: queryArg.filter },
        }),
        providesTags: ["UnitController"],
      }),
      getUserById: build.query<GetUserByIdApiResponse, GetUserByIdApiArg>({
        query: (queryArg) => ({ url: `/user/${queryArg.id}` }),
        providesTags: ["UserController"],
      }),
      getUsersCount: build.query<GetUsersCountApiResponse, GetUsersCountApiArg>(
        {
          query: (queryArg) => ({
            url: `/users/count`,
            params: { where: queryArg.where },
          }),
          providesTags: ["UserController"],
        }
      ),
      postUsersLogin: build.mutation<
        PostUsersLoginApiResponse,
        PostUsersLoginApiArg
      >({
        query: (queryArg) => ({
          url: `/users/login`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["UserController"],
      }),
      postUsersLogout: build.mutation<
        PostUsersLogoutApiResponse,
        PostUsersLogoutApiArg
      >({
        query: () => ({ url: `/users/logout`, method: "POST" }),
        invalidatesTags: ["UserController"],
      }),
      getUsersMe: build.query<GetUsersMeApiResponse, GetUsersMeApiArg>({
        query: () => ({ url: `/users/me` }),
        providesTags: ["UserController"],
      }),
      postUsersResetPassword: build.mutation<
        PostUsersResetPasswordApiResponse,
        PostUsersResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/users/reset-password`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["UserController"],
      }),
      postUsers: build.mutation<PostUsersApiResponse, PostUsersApiArg>({
        query: (queryArg) => ({
          url: `/users`,
          method: "POST",
          body: queryArg.userWithPassword,
        }),
        invalidatesTags: ["UserController"],
      }),
      getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
        query: () => ({ url: `/users` }),
        providesTags: ["UserController"],
      }),
      getUsersWithReviews: build.query<
        GetUsersWithReviewsApiResponse,
        GetUsersWithReviewsApiArg
      >({
        query: () => ({ url: `/users-with-reviews` }),
        providesTags: ["EmployeeReviewController"],
      }),
      getWhoAmI: build.query<GetWhoAmIApiResponse, GetWhoAmIApiArg>({
        query: () => ({ url: `/whoAmI` }),
        providesTags: ["UserController"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as vmsApi };
export type Get360ReviewsByUserByUserIdApiResponse =
  /** status 200 Array of 360 Reviews for a specific User */ FeedbackSurveyWithRelations[];
export type Get360ReviewsByUserByUserIdApiArg = {
  userId: string;
};
export type GetInventoryLocationsCountApiResponse =
  /** status 200 InventoryLocation model count */ LoopbackCount;
export type GetInventoryLocationsCountApiArg = {
  where?: any;
};
export type GetInventoryLocationsByIdApiResponse =
  /** status 200 InventoryLocation model instance */ InventoryLocationWithRelations;
export type GetInventoryLocationsByIdApiArg = {
  id: string;
};
export type DeleteInventoryLocationsByIdApiResponse = unknown;
export type DeleteInventoryLocationsByIdApiArg = {
  id: string;
};
export type PostInventoryLocationsApiResponse =
  /** status 200 InventoryLocation model instance */ InventoryLocation;
export type PostInventoryLocationsApiArg = {
  newInventoryLocation: NewInventoryLocation;
};
export type GetInventoryLocationsApiResponse =
  /** status 200 Array of InventoryLocation model instances with related Development data */ InventoryLocationWithRelations[];
export type GetInventoryLocationsApiArg = {
  filter?: any;
};
export type GetAccessApplicationsCountApiResponse =
  /** status 200 AccessApplication model count */ LoopbackCount;
export type GetAccessApplicationsCountApiArg = {
  where?: any;
};
export type PutAccessApplicationsByIdApiResponse = unknown;
export type PutAccessApplicationsByIdApiArg = {
  id: string;
  updateAccessApplication: UpdateAccessApplication;
};
export type GetAccessApplicationsByIdApiResponse =
  /** status 200 AccessApplication model instance */ AccessApplicationWithRelations;
export type GetAccessApplicationsByIdApiArg = {
  id: string;
};
export type DeleteAccessApplicationsByIdApiResponse = unknown;
export type DeleteAccessApplicationsByIdApiArg = {
  id: string;
};
export type PostAccessApplicationsApiResponse =
  /** status 200 AccessApplication model instance */ AccessApplication;
export type PostAccessApplicationsApiArg = {
  newAccessApplication: NewAccessApplication;
};
export type GetAccessApplicationsApiResponse =
  /** status 200 Array of AccessApplication model instances with related User and Development data */ AccessApplicationWithRelations[];
export type GetAccessApplicationsApiArg = {
  filter?: any;
};
export type GetAccessLogsCountApiResponse =
  /** status 200 AccessLog model count */ LoopbackCount;
export type GetAccessLogsCountApiArg = {
  where?: any;
};
export type GetAccessLogsByIdApiResponse =
  /** status 200 AccessLog model instance */ AccessLogWithRelations;
export type GetAccessLogsByIdApiArg = {
  id: string;
};
export type DeleteAccessLogsByIdApiResponse = unknown;
export type DeleteAccessLogsByIdApiArg = {
  id: string;
};
export type PostAccessLogsApiResponse =
  /** status 200 AccessLog model instance */ AccessLog;
export type PostAccessLogsApiArg = {
  newAccessLog: NewAccessLog;
};
export type GetAccessLogsApiResponse =
  /** status 200 Array of AccessLog model instances */ AccessLogWithRelations[];
export type GetAccessLogsApiArg = {
  filter?: any;
};
export type PostAddEmployeeReviewApiResponse =
  /** status 200 Employee Review */ EmployeeReview;
export type PostAddEmployeeReviewApiArg = {
  employeeReviewData: EmployeeReviewData;
};
export type PostAddInviteApiResponse = /** status 200 Invite */ Invite;
export type PostAddInviteApiArg = {
  inviteData: InviteData;
};
export type PostAddLifeWheelApiResponse =
  /** status 200 Life Wheel */ LifeWheel;
export type PostAddLifeWheelApiArg = {
  lifeWheelData: LifeWheelData;
};
export type PostBackOrdersApiResponse =
  /** status 200 BackOrder model instance */ BackOrder;
export type PostBackOrdersApiArg = {
  newBackOrder: NewBackOrder;
};
export type GetBackOrdersApiResponse =
  /** status 200 Array of BackOrders model instances with relations */ BackOrderWithRelations[];
export type GetBackOrdersApiArg = {
  filter?: any;
};
export type PatchBomByIdApiResponse = unknown;
export type PatchBomByIdApiArg = {
  id: string;
  billOfMaterialPartial: BillOfMaterialPartial;
};
export type GetBomByIdApiResponse =
  /** status 200 Bom model instance */ BillOfMaterialWithRelations;
export type GetBomByIdApiArg = {
  id: string;
};
export type DeleteBomByIdApiResponse = unknown;
export type DeleteBomByIdApiArg = {
  id: string;
};
export type PostBomApiResponse =
  /** status 200 Bom model instance */ BillOfMaterial;
export type PostBomApiArg = {
  bomData: BomData;
};
export type GetBomsCountApiResponse =
  /** status 200 Bom model count */ LoopbackCount;
export type GetBomsCountApiArg = {
  where?: any;
};
export type GetBomsByUnitIdApiResponse =
  /** status 200 Array of Bom model instances filtered by unitId */ BillOfMaterialWithRelations[];
export type GetBomsByUnitIdApiArg = {
  unitId: string;
};
export type GetBomsApiResponse =
  /** status 200 Array of Bom model instances */ BillOfMaterialWithRelations[];
export type GetBomsApiArg = {
  filter?: any;
};
export type PostClientApiResponse = /** status 200 Create client */ Client;
export type PostClientApiArg = {
  clientData: ClientData;
};
export type GetClientsApiResponse =
  /** status 200 Array of Client model instances */ ClientWithRelations[];
export type GetClientsApiArg = {
  filter?: any;
};
export type PostCreateAgreementApiResponse =
  /** status 200 create agreement */ Agreement;
export type PostCreateAgreementApiArg = {
  agreementData: AgreementData;
};
export type PostCreateProcurementProcessApiResponse =
  /** status 200 Procurement Process model instance */ NewProcurementProcess;
export type PostCreateProcurementProcessApiArg = {
  newProcurementProcess: NewProcurementProcess;
};
export type PostCreatePurchaseOrderApiResponse =
  /** status 200 Purchase Order */ PurchaseOrder;
export type PostCreatePurchaseOrderApiArg = {
  purchaseOrderData: PurchaseOrderData;
};
export type PostCreateRequisitionApiResponse =
  /** status 200 Requisition */ Requisition;
export type PostCreateRequisitionApiArg = {
  requisitionData: RequisitionData;
};
export type PostCreateRequisitionReviewApiResponse =
  /** status 200 Requisition Review */ RequisitionReview;
export type PostCreateRequisitionReviewApiArg = {
  requisitionReviewData: RequisitionReviewData;
};
export type PostDevelopmentApiResponse =
  /** status 200 Development */ Development;
export type PostDevelopmentApiArg = {
  developmentData: DevelopmentData;
};
export type GetDevelopmentsCountApiResponse =
  /** status 200 Development model count */ LoopbackCount;
export type GetDevelopmentsCountApiArg = {
  where?: any;
};
export type GetDevelopmentsProjectsApiResponse =
  /** status 200 Array of projects from all Development model instances */ {
    projectId?: string;
    name?: string;
  }[];
export type GetDevelopmentsProjectsApiArg = void;
export type PutDevelopmentsByIdApiResponse = unknown;
export type PutDevelopmentsByIdApiArg = {
  id: string;
  development: Development;
};
export type PatchDevelopmentsByIdApiResponse = unknown;
export type PatchDevelopmentsByIdApiArg = {
  id: string;
  developmentPartial: DevelopmentPartial;
};
export type GetDevelopmentsByIdApiResponse =
  /** status 200 Development model instance */ DevelopmentWithRelations;
export type GetDevelopmentsByIdApiArg = {
  id: string;
  filter?: any;
};
export type DeleteDevelopmentsByIdApiResponse = unknown;
export type DeleteDevelopmentsByIdApiArg = {
  id: string;
};
export type PatchDevelopmentsApiResponse =
  /** status 200 Development PATCH success count */ LoopbackCount;
export type PatchDevelopmentsApiArg = {
  where?: any;
  developmentPartial: DevelopmentPartial;
};
export type GetDevelopmentsApiResponse =
  /** status 200 Array of Development model instances */ DevelopmentWithRelations[];
export type GetDevelopmentsApiArg = void;
export type PatchEmployeeReviewByIdApiResponse = unknown;
export type PatchEmployeeReviewByIdApiArg = {
  id: string;
  employeeReviewPartial: EmployeeReviewPartial;
};
export type GetEmployeeReviewByIdApiResponse =
  /** status 200 Employee Review model instance */ EmployeeReviewWithRelations;
export type GetEmployeeReviewByIdApiArg = {
  id: string;
};
export type GetEmployeeReviewsByUserByUserIdApiResponse =
  /** status 200 Array of Employee Reviews for a specific User */ EmployeeReviewWithRelations[];
export type GetEmployeeReviewsByUserByUserIdApiArg = {
  userId: string;
};
export type GetFeedbackSurveyByIdApiResponse =
  /** status 200 Feedback Survey model instance */ FeedbackSurveyWithRelations;
export type GetFeedbackSurveyByIdApiArg = {
  id: string;
};
export type PostFeedbackSurveyApiResponse =
  /** status 200 Feedback Survey */ FeedbackSurvey;
export type PostFeedbackSurveyApiArg = {
  feedbackSurveyData: FeedbackSurveyData;
};
export type PostGetSignedUrlApiResponse =
  /** status 200 Get Signed URL for S3 upload */ {
    url?: string;
  };
export type PostGetSignedUrlApiArg = {
  body: {
    fileName: string;
    fileType: string;
  };
};
export type GetInventoriesCountApiResponse =
  /** status 200 Inventory model count */ LoopbackCount;
export type GetInventoriesCountApiArg = {
  where?: any;
};
export type GetInventoriesByIdApiResponse =
  /** status 200 Inventory model instance */ InventoryWithRelations;
export type GetInventoriesByIdApiArg = {
  id: string;
};
export type DeleteInventoriesByIdApiResponse = unknown;
export type DeleteInventoriesByIdApiArg = {
  id: string;
};
export type PostInventoriesApiResponse =
  /** status 200 Inventory model instance */ Inventory;
export type PostInventoriesApiArg = {
  newInventory: NewInventory;
};
export type GetInventoriesApiResponse =
  /** status 200 Array of Inventory model instances */ InventoryWithRelations[];
export type GetInventoriesApiArg = {
  filter?: any;
};
export type PatchInviteMarkAsScannedByIdApiResponse = unknown;
export type PatchInviteMarkAsScannedByIdApiArg = {
  id: string;
};
export type GetInviteByIdApiResponse =
  /** status 200 Invite model instance */ InviteWithRelations;
export type GetInviteByIdApiArg = {
  id: string;
};
export type GetInvitesApiResponse = /** status 200 Invite model instance */ any;
export type GetInvitesApiArg = {
  timeZone?: string;
};
export type GetItemCategoriesCountApiResponse =
  /** status 200 ItemCategory model count */ LoopbackCount;
export type GetItemCategoriesCountApiArg = {
  where?: any;
};
export type GetItemCategoriesByIdApiResponse =
  /** status 200 ItemCategory model instance */ ItemCategoryWithRelations;
export type GetItemCategoriesByIdApiArg = {
  id: string;
};
export type DeleteItemCategoriesByIdApiResponse = unknown;
export type DeleteItemCategoriesByIdApiArg = {
  id: string;
};
export type PostItemCategoriesApiResponse =
  /** status 200 ItemCategory model instance */ ItemCategory;
export type PostItemCategoriesApiArg = {
  newItemCategory: NewItemCategory;
};
export type GetItemCategoriesApiResponse =
  /** status 200 Array of ItemCategory model instances */ ItemCategoryWithRelations[];
export type GetItemCategoriesApiArg = {
  filter?: any;
};
export type GetItemLocationsCountApiResponse =
  /** status 200 ItemLocation model count */ LoopbackCount;
export type GetItemLocationsCountApiArg = {
  where?: any;
};
export type GetItemLocationsByIdApiResponse =
  /** status 200 ItemLocation model instance */ ItemLocationWithRelations;
export type GetItemLocationsByIdApiArg = {
  id: string;
};
export type DeleteItemLocationsByIdApiResponse = unknown;
export type DeleteItemLocationsByIdApiArg = {
  id: string;
};
export type PostItemLocationsApiResponse =
  /** status 200 ItemLocation model instance */ ItemLocation;
export type PostItemLocationsApiArg = {
  newItemLocation: NewItemLocation;
};
export type GetItemLocationsApiResponse =
  /** status 200 Array of ItemLocation model instances with relations */ ItemLocationWithRelations[];
export type GetItemLocationsApiArg = {
  filter?: any;
};
export type GetItemTransfersCountApiResponse =
  /** status 200 ItemTransfer model count */ LoopbackCount;
export type GetItemTransfersCountApiArg = {
  where?: any;
};
export type GetItemTransfersByIdApiResponse =
  /** status 200 ItemTransfer model instance */ ItemTransferWithRelations;
export type GetItemTransfersByIdApiArg = {
  id: string;
};
export type DeleteItemTransfersByIdApiResponse = unknown;
export type DeleteItemTransfersByIdApiArg = {
  id: string;
};
export type PostItemTransfersApiResponse =
  /** status 200 ItemTransfer model instance */ ItemTransfer;
export type PostItemTransfersApiArg = {
  newItemTransfer: NewItemTransfer;
};
export type GetItemTransfersApiResponse =
  /** status 200 Array of ItemTransfer model instances including relations */ ItemTransferWithRelations[];
export type GetItemTransfersApiArg = {
  filter?: any;
  limit?: number;
  skip?: number;
};
export type GetItemsCountApiResponse =
  /** status 200 Item model count */ LoopbackCount;
export type GetItemsCountApiArg = {
  where?: any;
};
export type GetItemsByIdApiResponse =
  /** status 200 Item model instance */ ItemWithRelations;
export type GetItemsByIdApiArg = {
  id: string;
};
export type DeleteItemsByIdApiResponse = unknown;
export type DeleteItemsByIdApiArg = {
  id: string;
};
export type PostItemsApiResponse = /** status 200 Item model instance */ Item;
export type PostItemsApiArg = {
  newItem: NewItem;
};
export type GetItemsApiResponse =
  /** status 200 Array of Item model instances including relations */ ItemWithRelations[];
export type GetItemsApiArg = {
  filter?: any;
  limit?: number;
  skip?: number;
};
export type PostItemsIssuedApiResponse =
  /** status 200 Item Issues model instance */ ItemIssued;
export type PostItemsIssuedApiArg = {
  newItemsIssued: NewItemsIssued;
};
export type GetItemsIssuedApiResponse =
  /** status 200 Array of Items Issued model instances with relations */ ItemIssuedWithRelations[];
export type GetItemsIssuedApiArg = {
  filter?: any;
};
export type GetLifeWheelByIdApiResponse =
  /** status 200 Life Wheel model instance */ LifeWheelWithRelations;
export type GetLifeWheelByIdApiArg = {
  id: string;
};
export type GetLifeWheelsByUserByUserIdApiResponse =
  /** status 200 Array of Life Wheels for a specific User */ LifeWheelWithRelations[];
export type GetLifeWheelsByUserByUserIdApiArg = {
  userId: string;
};
export type GetPasswordResetIsResetLinkValidByResetIdApiResponse = unknown;
export type GetPasswordResetIsResetLinkValidByResetIdApiArg = {
  resetId: string;
};
export type PostPasswordResetRequestByUserEmailApiResponse = unknown;
export type PostPasswordResetRequestByUserEmailApiArg = {
  userEmail: string;
};
export type PostPasswordResetResetPasswordApiResponse = unknown;
export type PostPasswordResetResetPasswordApiArg = {
  /** The input for the reset password function */
  body: {
    resetRequestId: string;
    password: string;
  };
};
export type PostPayrollUploadApiResponse =
  /** status 200 Payroll voucher PDF upload response */ {
    message?: string;
    pdfUrl?: string;
  };
export type PostPayrollUploadApiArg = {
  /** Link to the Excel file containing payroll data */
  body: {
    excelFile?: string;
    paymentDesc?: string;
  };
};
export type GetPingApiResponse = /** status 200 Ping Response */ PingResponse;
export type GetPingApiArg = void;
export type PutProcurementProcessesByIdApiResponse =
  /** status 200 Procurement Process PUT success */ UpdatedProcurementProcess;
export type PutProcurementProcessesByIdApiArg = {
  id: string;
  procurementProcessPartial: ProcurementProcessPartial;
};
export type PatchProcurementProcessesByIdApiResponse = unknown;
export type PatchProcurementProcessesByIdApiArg = {
  id: string;
  procurementProcessPartial: ProcurementProcessPartial;
};
export type GetProcurementProcessesByIdApiResponse =
  /** status 200 Procurement Process model instance */ ProcurementProcessWithRelations;
export type GetProcurementProcessesByIdApiArg = {
  id: string;
};
export type DeleteProcurementProcessesByIdApiResponse = unknown;
export type DeleteProcurementProcessesByIdApiArg = {
  id: string;
};
export type GetProcurementProcessesApiResponse =
  /** status 200 Array of Procurement Process model instances */ ProcurementProcessWithRelations[];
export type GetProcurementProcessesApiArg = {
  currentTask?: string;
  limit?: number;
  skip?: number;
};
export type GetPropertiesApiResponse =
  /** status 200 Array of Property model instances with related Client data */ PropertyWithRelations[];
export type GetPropertiesApiArg = {
  filter?: any;
};
export type PostPropertyApiResponse =
  /** status 200 Create property */ Property;
export type PostPropertyApiArg = {
  propertyData: PropertyData;
};
export type GetPurchaseOrderByIdApiResponse =
  /** status 200 Purchase Order model instance */ PurchaseOrderWithRelations;
export type GetPurchaseOrderByIdApiArg = {
  id: string;
};
export type GetRequisitionByIdApiResponse =
  /** status 200 Requisition model instance */ RequisitionWithRelations;
export type GetRequisitionByIdApiArg = {
  id: string;
};
export type GetRequisitionReviewByIdApiResponse =
  /** status 200 Requisition Review model instance */ RequisitionReviewWithRelations;
export type GetRequisitionReviewByIdApiArg = {
  id: string;
};
export type GetRequisitionReviewsApiResponse =
  /** status 200 Array of RequisitionReview model instances */ RequisitionReviewWithRelations[];
export type GetRequisitionReviewsApiArg = void;
export type GetRequisitionsNewApiResponse =
  /** status 200 Requisitions model instance */ any;
export type GetRequisitionsNewApiArg = {
  site?: string;
};
export type GetRequisitionsApiResponse =
  /** status 200 Array of Requisition model instances */ RequisitionWithRelations[];
export type GetRequisitionsApiArg = void;
export type GetStakeholdersByTypeByTypeApiResponse =
  /** status 200 Array of Stakeholder model instances filtered by type */ StakeholderWithRelations[];
export type GetStakeholdersByTypeByTypeApiArg = {
  type: string;
};
export type GetStakeholdersCountApiResponse =
  /** status 200 Stakeholder model count */ LoopbackCount;
export type GetStakeholdersCountApiArg = {
  where?: any;
};
export type PatchStakeholdersByIdApiResponse = unknown;
export type PatchStakeholdersByIdApiArg = {
  id: string;
  stakeholderPartial: StakeholderPartial;
};
export type GetStakeholdersByIdApiResponse =
  /** status 200 Stakeholder model instance */ StakeholderWithRelations;
export type GetStakeholdersByIdApiArg = {
  id: string;
};
export type DeleteStakeholdersByIdApiResponse = unknown;
export type DeleteStakeholdersByIdApiArg = {
  id: string;
};
export type PostStakeholdersApiResponse =
  /** status 200 Stakeholder model instance */ Stakeholder;
export type PostStakeholdersApiArg = {
  newStakeholder: NewStakeholder;
};
export type GetStakeholdersApiResponse =
  /** status 200 Array of Stakeholder model instances */ StakeholderWithRelations[];
export type GetStakeholdersApiArg = {
  filter?: any;
};
export type PutSupervisorsByIdApiResponse = unknown;
export type PutSupervisorsByIdApiArg = {
  id: string;
  supervisorPartial: SupervisorPartial;
};
export type PatchSupervisorsByIdApiResponse = unknown;
export type PatchSupervisorsByIdApiArg = {
  id: string;
  supervisorPartial: SupervisorPartial;
};
export type DeleteSupervisorsByIdApiResponse = unknown;
export type DeleteSupervisorsByIdApiArg = {
  id: string;
};
export type PostSupervisorsApiResponse =
  /** status 200 Supervisor model instance */ Supervisor;
export type PostSupervisorsApiArg = {
  supervisorData: SupervisorData;
};
export type GetSupervisorsApiResponse =
  /** status 200 Array of Supervisor model instances with related User and Supervisee data */ SupervisorWithRelations[];
export type GetSupervisorsApiArg = {
  filter?: any;
};
export type PostUnitApiResponse = /** status 200 Unit */ Unit;
export type PostUnitApiArg = {
  unitData: UnitData;
};
export type GetUnitsByProjectByProjectIdApiResponse =
  /** status 200 Array of Unit model instances by projectId */ UnitWithRelations[];
export type GetUnitsByProjectByProjectIdApiArg = {
  projectId: string;
  filter?: any;
};
export type GetUnitsCountApiResponse =
  /** status 200 Unit model count */ LoopbackCount;
export type GetUnitsCountApiArg = {
  where?: any;
};
export type PutUnitsByIdApiResponse = unknown;
export type PutUnitsByIdApiArg = {
  id: string;
  unit: Unit;
};
export type PatchUnitsByIdApiResponse = unknown;
export type PatchUnitsByIdApiArg = {
  id: string;
  unitPartial: UnitPartial;
};
export type GetUnitsByIdApiResponse =
  /** status 200 Unit model instance */ UnitWithRelations;
export type GetUnitsByIdApiArg = {
  id: string;
  filter?: any;
};
export type DeleteUnitsByIdApiResponse = unknown;
export type DeleteUnitsByIdApiArg = {
  id: string;
};
export type PatchUnitsApiResponse =
  /** status 200 Unit PATCH success count */ LoopbackCount;
export type PatchUnitsApiArg = {
  where?: any;
  unitPartial: UnitPartial;
};
export type GetUnitsApiResponse =
  /** status 200 Array of Unit model instances */ UnitWithRelations[];
export type GetUnitsApiArg = {
  filter?: any;
};
export type GetUserByIdApiResponse =
  /** status 200 A specified user user */ User;
export type GetUserByIdApiArg = {
  id: string;
};
export type GetUsersCountApiResponse =
  /** status 200 User model count */ LoopbackCount;
export type GetUsersCountApiArg = {
  where?: any;
};
export type PostUsersLoginApiResponse = /** status 200 Token */ User;
export type PostUsersLoginApiArg = {
  /** The input of login function */
  body: {
    email: string;
    password: string;
  };
};
export type PostUsersLogoutApiResponse = unknown;
export type PostUsersLogoutApiArg = void;
export type GetUsersMeApiResponse = /** status 200 The current user */ User;
export type GetUsersMeApiArg = void;
export type PostUsersResetPasswordApiResponse = /** status 200 User */ User;
export type PostUsersResetPasswordApiArg = {
  /** The input of password reset function */
  body: {
    password: string;
    repeatPassword: string;
  };
};
export type PostUsersApiResponse = /** status 200 User */ User;
export type PostUsersApiArg = {
  userWithPassword: UserWithPassword;
};
export type GetUsersApiResponse = /** status 200 All users */ User[];
export type GetUsersApiArg = void;
export type GetUsersWithReviewsApiResponse =
  /** status 200 Array of users who have submitted an employee review */ User[];
export type GetUsersWithReviewsApiArg = void;
export type GetWhoAmIApiResponse = /** status 200 Return current user */ {
  token?: string;
};
export type GetWhoAmIApiArg = void;
export type FeedbackSurveyResponse = {
  questionId: string;
  responseBoolean?: boolean;
  responseText?: string;
};
export type FeedbackSurveyWithRelations = {
  id?: string;
  userId: string;
  reviewerId: string;
  reviewPeriod: string;
  responses: FeedbackSurveyResponse[];
};
export type LoopbackCount = {
  count?: number;
};
export type Project = {
  id?: string;
  name: string;
  description?: string;
  status: string;
  startDate?: string;
};
export type DevelopmentWithRelations = {
  id?: string;
  name: string;
  description?: string;
  projects: Project[];
};
export type InventoryLocationWithRelations = {
  id?: string;
  locationCode: string;
  developmentId?: string;
  description?: string;
  development?: DevelopmentWithRelations;
};
export type InventoryLocation = {
  id?: string;
  locationCode: string;
  developmentId?: string;
  description?: string;
};
export type NewInventoryLocation = {
  locationCode: string;
  developmentId?: string;
  description?: string;
};
export type UpdateAccessApplication = {
  id?: string;
  userId?: string;
  purposeOfVisit: string;
  approved?: boolean;
  developmentId?: string;
  proposedDateOfVisit?: string;
  proposedTimeOfVisit?: string;
  proposedTimeToLeave?: string;
  [key: string]: any;
};
export type UserCredentialsWithRelations = {
  id?: string;
  password: string;
  userId: string;
};
export type UserRefreshTokenWithRelations = {
  id?: string;
  ttl: number;
  creation?: string;
  userId: string;
};
export type UserWithRelations = {
  id?: string;
  email: string;
  name?: string;
  department?: string;
  gender: string;
  jobTitle?: any;
  idNumber?: any;
  roles?: string[];
  previousToken?: string;
  userCredentials?: UserCredentialsWithRelations;
  userRefreshTokens?: UserRefreshTokenWithRelations[];
};
export type AccessApplicationWithRelations = {
  id?: string;
  userId?: string;
  purposeOfVisit: string;
  approved?: boolean;
  developmentId?: string;
  proposedDateOfVisit?: string;
  proposedTimeOfVisit?: string;
  proposedTimeToLeave?: string;
  user?: UserWithRelations;
  development?: DevelopmentWithRelations;
  [key: string]: any;
};
export type AccessApplication = {
  id?: string;
  userId?: string;
  purposeOfVisit: string;
  approved?: boolean;
  developmentId?: string;
  proposedDateOfVisit?: string;
  proposedTimeOfVisit?: string;
  proposedTimeToLeave?: string;
  [key: string]: any;
};
export type NewAccessApplication = {
  userId?: string;
  purposeOfVisit: string;
  approved?: boolean;
  developmentId?: string;
  proposedDateOfVisit?: string;
  proposedTimeOfVisit?: string;
  proposedTimeToLeave?: string;
  [key: string]: any;
};
export type AccessLogWithRelations = {
  id?: string;
  accessApplication: AccessApplication;
  date: string;
  timeIn: string;
  timeOut?: string;
};
export type AccessLog = {
  id?: string;
  accessApplication: AccessApplication;
  date: string;
  timeIn: string;
  timeOut?: string;
};
export type NewAccessLog = {
  accessApplication: AccessApplication;
  date: string;
  timeIn: string;
  timeOut?: string;
};
export type PerformanceItem = {
  ranking?: number;
  title?: string;
  rating?: string;
  managerRating?: string;
};
export type EmployeeReview = {
  id?: string;
  reviewPeriod: string;
  accomplishments?: PerformanceItem[];
  quarterlyGoals?: PerformanceItem[];
  monthlyGoals?: PerformanceItem[];
  areaToImprove: string;
  quarterlyObjective: string;
  examplesOfExcellence: string;
  mainGoalToAchieve: string;
  turnUpOnTime: boolean;
  dailyCheckIn: boolean;
  reportsOnTime: boolean;
  userId?: string;
};
export type EmployeeReviewData = {
  reviewPeriod: string;
  accomplishments?: PerformanceItem[];
  quarterlyGoals?: PerformanceItem[];
  monthlyGoals?: PerformanceItem[];
  areaToImprove: string;
  quarterlyObjective: string;
  examplesOfExcellence: string;
  mainGoalToAchieve: string;
  turnUpOnTime: boolean;
  dailyCheckIn: boolean;
  reportsOnTime: boolean;
};
export type Invite = {
  id?: string;
  isPermanentVisitor: boolean;
  visitorName: string;
  visitorEmail: string;
  visitorWhatsApp: string;
  meetingWith?: string;
  meetingDateTime?: string;
  meetingCreatedBy?: string;
  scanned?: boolean;
  idNumber: string;
  idCardImageFilename?: string;
};
export type InviteData = {
  isPermanentVisitor: boolean;
  visitorName: string;
  visitorEmail: string;
  visitorWhatsApp: string;
  meetingWith?: string;
  meetingDateTime?: string;
  meetingCreatedBy?: string;
  scanned?: boolean;
  idNumber: string;
  idCardImageFilename?: string;
};
export type LifeWheelResponse = {
  current: number[];
  desired: number[];
};
export type LifeWheel = {
  id?: string;
  userId: string;
  reviewPeriod: string;
  responses: LifeWheelResponse[];
};
export type LifeWheelData = {
  userId: string;
  reviewPeriod: string;
  responses: LifeWheelResponse[];
};
export type BackOrder = {
  id?: string;
  itemId?: string;
  stakeholderId?: string;
  stock: number;
  totalValue: number;
};
export type NewBackOrder = {
  itemId?: string;
  stakeholderId?: string;
  stock: number;
  totalValue: number;
};
export type ItemCategoryWithRelations = {
  id?: string;
  categoryCode: string;
  description: string;
};
export type InventoryWithRelations = {
  id?: string;
  inventoryCode: string;
  description: string;
};
export type ItemWithRelations = {
  id?: string;
  itemCode: string;
  description: string;
  quantityUnit: string;
  itemCategoryId?: string;
  itemCost: number;
  inventoryId?: string;
  itemCategory?: ItemCategoryWithRelations;
  inventory?: InventoryWithRelations;
};
export type ContactInfo = {
  phone: string;
  email: string;
  contactPerson?: string;
};
export type Address = {
  street: string;
  city: string;
  state?: string;
  zipCode?: string;
  country?: string;
};
export type StakeholderWithRelations = {
  id?: string;
  name: string;
  type: string;
  contactInfo: ContactInfo[];
  address: Address[];
  tin?: string;
  paymentTerms?: string;
  supplierCode?: string;
  customerCode?: string;
  productCategories?: string[];
  rating?: number;
  orderHistory?: object[];
  preferences?: object;
};
export type BackOrderWithRelations = {
  id?: string;
  itemId?: string;
  stakeholderId?: string;
  stock: number;
  totalValue: number;
  item?: ItemWithRelations;
  stakeholder?: StakeholderWithRelations;
};
export type BomItem = {
  itemId: string;
  description: string;
  quantity: number;
  quantityUnit: string;
  rate: number;
  inventoryCode: string;
};
export type BomSection = {
  sectionNumber: string;
  sectionTitle: string;
  items: BomItem[];
};
export type BillOfMaterialPartial = {
  id?: string;
  unitId?: string;
  sections?: BomSection[];
};
export type BillOfMaterialWithRelations = {
  id?: string;
  unitId: string;
  sections: BomSection[];
};
export type BillOfMaterial = {
  id?: string;
  unitId: string;
  sections: BomSection[];
};
export type BomData = {
  unitId: string;
  sections: BomSection[];
};
export type Client = {
  id?: string;
  clientName: string;
  clientIdNumber: string;
  clientEmail: string;
};
export type ClientData = {
  clientName: string;
  clientIdNumber: string;
  clientEmail: string;
};
export type ClientWithRelations = {
  id?: string;
  clientName: string;
  clientIdNumber: string;
  clientEmail: string;
};
export type DepositPayment = {
  date: string;
  receiptNumber: string;
  amount: number;
};
export type InstallmentPayment = {
  date?: string;
  paymentAmount?: number;
};
export type Agreement = {
  id?: string;
  clientName: string;
  clientIdNumber: string;
  clientCompanyName?: string;
  clientAddress: string;
  company: any;
  representedBy: any;
  purchasePrice: number;
  unitNumber: string;
  landShare?: string;
  clientDob?: string;
  block?: string;
  paymentsToDate: DepositPayment[];
  firstInstallmentPaymentDate: string;
  lastInstallmentPaymentDate: string;
  numberOfInterestFreeMonths: number;
  installmentPaymentsDue?: InstallmentPayment[];
  remainingAmount?: number;
  remainingAmountWithInterest?: number;
  monthlyCapitalPayment?: number;
  monthlyInstallmentAmount?: number;
  propertyDescription: string;
  unitArea: number;
  chargeInterest: boolean;
  contractReference: string;
};
export type AgreementData = {
  clientName: string;
  clientIdNumber: string;
  clientCompanyName?: string;
  clientAddress: string;
  company: any;
  representedBy: any;
  purchasePrice: number;
  unitNumber: string;
  landShare?: string;
  clientDob?: string;
  block?: string;
  paymentsToDate: DepositPayment[];
  firstInstallmentPaymentDate: string;
  lastInstallmentPaymentDate: string;
  numberOfInterestFreeMonths: number;
  installmentPaymentsDue?: InstallmentPayment[];
  remainingAmount?: number;
  remainingAmountWithInterest?: number;
  monthlyCapitalPayment?: number;
  monthlyInstallmentAmount?: number;
  propertyDescription: string;
  unitArea: number;
  chargeInterest: boolean;
  contractReference: string;
};
export type EntityIdentity = {
  id?: any;
  name?: any;
};
export type Quantity = {
  quantityValue: number;
  quantityUnit: string;
};
export type RequisitionItem = {
  description?: string;
  quantity: Quantity;
  unit?: EntityIdentity;
  procured?: boolean;
  rate?: number;
  totalCostPerItem?: number;
  discountPerItem?: number;
  VAT?: boolean;
};
export type Attachment = {
  name: string;
  url: string;
  [key: string]: any;
};
export type Requisition = {
  id?: string;
  title: string;
  development: EntityIdentity;
  project: EntityIdentity;
  totalCost?: number;
  reqNumber?: string;
  purchaseOrderNumber?: string;
  approved?: boolean;
  items: RequisitionItem[];
  documents?: Attachment[];
};
export type User = {
  id?: string;
  email: string;
  name?: string;
  department?: string;
  gender: string;
  jobTitle?: any;
  idNumber?: any;
  roles?: string[];
  previousToken?: string;
};
export type Quotation = {
  paymentTerms: string;
  remarks: string;
  supplier: EntityIdentity;
  attachments?: Attachment[];
  selected?: boolean;
  quotationItems?: RequisitionItem[];
  totalVAT?: number;
  totalb4VAT?: number;
  grandTotal?: number;
  totalDiscount?: number;
  [key: string]: any;
};
export type Review = {
  user: User;
  comment: string;
  approved: boolean;
  dateReviewed?: any;
  quotations?: Quotation[];
  [key: string]: any;
};
export type NewProcurementProcess = {
  currentTask: string;
  currentTaskOwner: string;
  previousTask: string;
  initiatedDate: string;
  lastActionedDate: string;
  status: string;
  requisition: Requisition;
  siteManagerReview?: Review;
  projectManagerReview?: Review;
  cpoReview?: Review;
  pcReview?: Review;
  pmReview?: Review;
  proChairReview?: Review;
  pcToUploadQuotations?: Review;
  initiatedByUser?: User;
  requisitionDocument?: Attachment;
  purchaseOrderDocument?: Attachment;
};
export type PurchaseOrder = {
  id?: string;
  requisitionId?: string;
  items: RequisitionItem[];
  userId?: string;
};
export type PurchaseOrderData = {
  requisitionId?: string;
  items: RequisitionItem[];
  userId?: string;
};
export type RequisitionData = {
  title: string;
  development: EntityIdentity;
  project: EntityIdentity;
  totalCost?: number;
  reqNumber?: string;
  purchaseOrderNumber?: string;
  approved?: boolean;
  items: RequisitionItem[];
  documents?: Attachment[];
};
export type Comment = {
  user?: object;
  comment: string;
};
export type RequisitionReview = {
  id?: string;
  comment: Comment;
  approved: boolean;
};
export type RequisitionReviewData = {
  comment: Comment;
  approved: boolean;
};
export type Development = {
  id?: string;
  name: string;
  description?: string;
  projects: Project[];
};
export type DevelopmentData = {
  name: string;
  description?: string;
  projects: Project[];
};
export type DevelopmentPartial = {
  id?: string;
  name?: string;
  description?: string;
  projects?: Project[];
};
export type EmployeeReviewPartial = {
  id?: string;
  reviewPeriod?: string;
  accomplishments?: PerformanceItem[];
  quarterlyGoals?: PerformanceItem[];
  monthlyGoals?: PerformanceItem[];
  areaToImprove?: string;
  quarterlyObjective?: string;
  examplesOfExcellence?: string;
  mainGoalToAchieve?: string;
  turnUpOnTime?: boolean;
  dailyCheckIn?: boolean;
  reportsOnTime?: boolean;
  userId?: string;
};
export type EmployeeReviewWithRelations = {
  id?: string;
  reviewPeriod: string;
  accomplishments?: PerformanceItem[];
  quarterlyGoals?: PerformanceItem[];
  monthlyGoals?: PerformanceItem[];
  areaToImprove: string;
  quarterlyObjective: string;
  examplesOfExcellence: string;
  mainGoalToAchieve: string;
  turnUpOnTime: boolean;
  dailyCheckIn: boolean;
  reportsOnTime: boolean;
  userId?: string;
  user?: UserWithRelations;
};
export type FeedbackSurvey = {
  id?: string;
  userId: string;
  reviewerId: string;
  reviewPeriod: string;
  responses: FeedbackSurveyResponse[];
};
export type FeedbackSurveyData = {
  userId: string;
  reviewerId: string;
  reviewPeriod: string;
  responses: FeedbackSurveyResponse[];
};
export type Inventory = {
  id?: string;
  inventoryCode: string;
  description: string;
};
export type NewInventory = {
  inventoryCode: string;
  description: string;
};
export type InviteWithRelations = {
  id?: string;
  isPermanentVisitor: boolean;
  visitorName: string;
  visitorEmail: string;
  visitorWhatsApp: string;
  meetingWith?: string;
  meetingDateTime?: string;
  meetingCreatedBy?: string;
  scanned?: boolean;
  idNumber: string;
  idCardImageFilename?: string;
};
export type ItemCategory = {
  id?: string;
  categoryCode: string;
  description: string;
};
export type NewItemCategory = {
  categoryCode: string;
  description: string;
};
export type ItemLocationWithRelations = {
  id?: string;
  itemId?: string;
  locationId?: string;
  stock: number;
  totalValue: number;
  item?: ItemWithRelations;
  location?: InventoryLocationWithRelations;
};
export type ItemLocation = {
  id?: string;
  itemId?: string;
  locationId?: string;
  stock: number;
  totalValue: number;
};
export type NewItemLocation = {
  itemId?: string;
  locationId?: string;
  stock: number;
  totalValue: number;
};
export type TargetLocation = {
  unit?: EntityIdentity;
  project?: EntityIdentity;
  development?: EntityIdentity;
  inventoryLocation?: {
    locationId: string;
    locationCode: string;
    description: string;
    developmentId: string;
    developmentName: string;
  };
  supplier?: EntityIdentity;
};
export type ItemTransferWithRelations = {
  id?: string;
  items: {
    itemId: string;
    itemCode: string;
    description: string;
    reasonForTransfer?: string;
    itemCost: number;
    quantity: {
      quantityValue: number;
      quantityUnit: string;
    };
  }[];
  sourceLocation: TargetLocation;
  sourceLocationType?: string;
  destinationLocationType?: string;
  destinationLocation: TargetLocation;
  documents?: Attachment[];
};
export type ItemTransfer = {
  id?: string;
  items: {
    itemId: string;
    itemCode: string;
    description: string;
    reasonForTransfer?: string;
    itemCost: number;
    quantity: {
      quantityValue: number;
      quantityUnit: string;
    };
  }[];
  sourceLocation: TargetLocation;
  sourceLocationType?: string;
  destinationLocationType?: string;
  destinationLocation: TargetLocation;
  documents?: Attachment[];
};
export type NewItemTransfer = {
  items: {
    itemId: string;
    itemCode: string;
    description: string;
    reasonForTransfer?: string;
    itemCost: number;
    quantity: {
      quantityValue: number;
      quantityUnit: string;
    };
  }[];
  sourceLocation: TargetLocation;
  sourceLocationType?: string;
  destinationLocationType?: string;
  destinationLocation: TargetLocation;
  documents?: Attachment[];
};
export type Item = {
  id?: string;
  itemCode: string;
  description: string;
  quantityUnit: string;
  itemCategoryId?: string;
  itemCost: number;
  inventoryId?: string;
};
export type NewItem = {
  itemCode: string;
  description: string;
  quantityUnit: string;
  itemCategoryId?: string;
  itemCost: number;
  inventoryId?: string;
};
export type ItemIssued = {
  id?: string;
  itemId?: string;
  unitId?: string;
  stock: number;
  totalValue: number;
};
export type NewItemsIssued = {
  itemId?: string;
  unitId?: string;
  stock: number;
  totalValue: number;
};
export type UnitWithRelations = {
  id?: string;
  unitName: string;
  projectId: string;
};
export type ItemIssuedWithRelations = {
  id?: string;
  itemId?: string;
  unitId?: string;
  stock: number;
  totalValue: number;
  item?: ItemWithRelations;
  unit?: UnitWithRelations;
};
export type LifeWheelWithRelations = {
  id?: string;
  userId: string;
  reviewPeriod: string;
  responses: LifeWheelResponse[];
};
export type PingResponse = {
  greeting?: string;
  date?: string;
  url?: string;
  headers?: {
    "Content-Type"?: string;
    [key: string]: any;
  };
};
export type UpdatedProcurementProcess = {
  id?: string;
  currentTask: string;
  currentTaskOwner: string;
  previousTask: string;
  initiatedDate: string;
  lastActionedDate: string;
  status: string;
  requisition: Requisition;
  siteManagerReview?: Review;
  projectManagerReview?: Review;
  cpoReview?: Review;
  pcReview?: Review;
  pmReview?: Review;
  proChairReview?: Review;
  pcToUploadQuotations?: Review;
  initiatedByUser?: User;
  requisitionDocument?: Attachment;
  purchaseOrderDocument?: Attachment;
};
export type ProcurementProcessPartial = {
  id?: string;
  currentTask?: string;
  currentTaskOwner?: string;
  previousTask?: string;
  initiatedDate?: string;
  lastActionedDate?: string;
  status?: string;
  requisition?: Requisition;
  siteManagerReview?: Review;
  projectManagerReview?: Review;
  cpoReview?: Review;
  pcReview?: Review;
  pmReview?: Review;
  proChairReview?: Review;
  pcToUploadQuotations?: Review;
  initiatedByUser?: User;
  requisitionDocument?: Attachment;
  purchaseOrderDocument?: Attachment;
};
export type ProcurementProcessWithRelations = {
  id?: string;
  currentTask: string;
  currentTaskOwner: string;
  previousTask: string;
  initiatedDate: string;
  lastActionedDate: string;
  status: string;
  requisition: Requisition;
  siteManagerReview?: Review;
  projectManagerReview?: Review;
  cpoReview?: Review;
  pcReview?: Review;
  pmReview?: Review;
  proChairReview?: Review;
  pcToUploadQuotations?: Review;
  initiatedByUser?: User;
  requisitionDocument?: Attachment;
  purchaseOrderDocument?: Attachment;
};
export type PropertyWithRelations = {
  id?: string;
  development: string;
  phase: string;
  unitNumber: string;
  clientId?: string;
  client?: ClientWithRelations;
};
export type Property = {
  id?: string;
  development: string;
  phase: string;
  unitNumber: string;
  clientId?: string;
};
export type PropertyData = {
  development: string;
  phase: string;
  unitNumber: string;
  clientId?: string;
};
export type PurchaseOrderWithRelations = {
  id?: string;
  requisitionId?: string;
  items: RequisitionItem[];
  userId?: string;
};
export type RequisitionWithRelations = {
  id?: string;
  title: string;
  development: EntityIdentity;
  project: EntityIdentity;
  totalCost?: number;
  reqNumber?: string;
  purchaseOrderNumber?: string;
  approved?: boolean;
  items: RequisitionItem[];
  documents?: Attachment[];
};
export type RequisitionReviewWithRelations = {
  id?: string;
  comment: Comment;
  approved: boolean;
};
export type StakeholderPartial = {
  id?: string;
  name?: string;
  type?: string;
  contactInfo?: ContactInfo[];
  address?: Address[];
  tin?: string;
  paymentTerms?: string;
  supplierCode?: string;
  customerCode?: string;
  productCategories?: string[];
  rating?: number;
  orderHistory?: object[];
  preferences?: object;
};
export type Stakeholder = {
  id?: string;
  name: string;
  type: string;
  contactInfo: ContactInfo[];
  address: Address[];
  tin?: string;
  paymentTerms?: string;
  supplierCode?: string;
  customerCode?: string;
  productCategories?: string[];
  rating?: number;
  orderHistory?: object[];
  preferences?: object;
};
export type NewStakeholder = {
  name: string;
  type: string;
  contactInfo: ContactInfo[];
  address: Address[];
  tin?: string;
  paymentTerms?: string;
  supplierCode?: string;
  customerCode?: string;
  productCategories?: string[];
  rating?: number;
  orderHistory?: object[];
  preferences?: object;
};
export type SuperVisee = {
  userId: string;
  name: string;
  department: string;
  jobTitle: string;
};
export type SupervisorPartial = {
  id?: string;
  userId?: string;
  supervisees?: SuperVisee[];
};
export type Supervisor = {
  userId?: string;
  supervisees?: SuperVisee[];
};
export type SupervisorData = {
  userId?: string;
  supervisees?: SuperVisee[];
};
export type SupervisorWithRelations = {
  id?: string;
  userId?: string;
  supervisees?: SuperVisee[];
  user?: UserWithRelations;
};
export type Unit = {
  id?: string;
  unitName: string;
  projectId: string;
};
export type UnitData = {
  unitName: string;
  projectId: string;
};
export type UnitPartial = {
  id?: string;
  unitName?: string;
  projectId?: string;
};
export type UserWithPassword = {
  email: string;
  name?: string;
  department?: string;
  gender: string;
  jobTitle?: any;
  idNumber?: any;
  password: string;
};
export const {
  useGet360ReviewsByUserByUserIdQuery,
  useGetInventoryLocationsCountQuery,
  useGetInventoryLocationsByIdQuery,
  useDeleteInventoryLocationsByIdMutation,
  usePostInventoryLocationsMutation,
  useGetInventoryLocationsQuery,
  useGetAccessApplicationsCountQuery,
  usePutAccessApplicationsByIdMutation,
  useGetAccessApplicationsByIdQuery,
  useDeleteAccessApplicationsByIdMutation,
  usePostAccessApplicationsMutation,
  useGetAccessApplicationsQuery,
  useGetAccessLogsCountQuery,
  useGetAccessLogsByIdQuery,
  useDeleteAccessLogsByIdMutation,
  usePostAccessLogsMutation,
  useGetAccessLogsQuery,
  usePostAddEmployeeReviewMutation,
  usePostAddInviteMutation,
  usePostAddLifeWheelMutation,
  usePostBackOrdersMutation,
  useGetBackOrdersQuery,
  usePatchBomByIdMutation,
  useGetBomByIdQuery,
  useDeleteBomByIdMutation,
  usePostBomMutation,
  useGetBomsCountQuery,
  useGetBomsByUnitIdQuery,
  useGetBomsQuery,
  usePostClientMutation,
  useGetClientsQuery,
  usePostCreateAgreementMutation,
  usePostCreateProcurementProcessMutation,
  usePostCreatePurchaseOrderMutation,
  usePostCreateRequisitionMutation,
  usePostCreateRequisitionReviewMutation,
  usePostDevelopmentMutation,
  useGetDevelopmentsCountQuery,
  useGetDevelopmentsProjectsQuery,
  usePutDevelopmentsByIdMutation,
  usePatchDevelopmentsByIdMutation,
  useGetDevelopmentsByIdQuery,
  useDeleteDevelopmentsByIdMutation,
  usePatchDevelopmentsMutation,
  useGetDevelopmentsQuery,
  usePatchEmployeeReviewByIdMutation,
  useGetEmployeeReviewByIdQuery,
  useGetEmployeeReviewsByUserByUserIdQuery,
  useGetFeedbackSurveyByIdQuery,
  usePostFeedbackSurveyMutation,
  usePostGetSignedUrlMutation,
  useGetInventoriesCountQuery,
  useGetInventoriesByIdQuery,
  useDeleteInventoriesByIdMutation,
  usePostInventoriesMutation,
  useGetInventoriesQuery,
  usePatchInviteMarkAsScannedByIdMutation,
  useGetInviteByIdQuery,
  useGetInvitesQuery,
  useGetItemCategoriesCountQuery,
  useGetItemCategoriesByIdQuery,
  useDeleteItemCategoriesByIdMutation,
  usePostItemCategoriesMutation,
  useGetItemCategoriesQuery,
  useGetItemLocationsCountQuery,
  useGetItemLocationsByIdQuery,
  useDeleteItemLocationsByIdMutation,
  usePostItemLocationsMutation,
  useGetItemLocationsQuery,
  useGetItemTransfersCountQuery,
  useGetItemTransfersByIdQuery,
  useDeleteItemTransfersByIdMutation,
  usePostItemTransfersMutation,
  useGetItemTransfersQuery,
  useGetItemsCountQuery,
  useGetItemsByIdQuery,
  useDeleteItemsByIdMutation,
  usePostItemsMutation,
  useGetItemsQuery,
  usePostItemsIssuedMutation,
  useGetItemsIssuedQuery,
  useGetLifeWheelByIdQuery,
  useGetLifeWheelsByUserByUserIdQuery,
  useGetPasswordResetIsResetLinkValidByResetIdQuery,
  usePostPasswordResetRequestByUserEmailMutation,
  usePostPasswordResetResetPasswordMutation,
  usePostPayrollUploadMutation,
  useGetPingQuery,
  usePutProcurementProcessesByIdMutation,
  usePatchProcurementProcessesByIdMutation,
  useGetProcurementProcessesByIdQuery,
  useDeleteProcurementProcessesByIdMutation,
  useGetProcurementProcessesQuery,
  useGetPropertiesQuery,
  usePostPropertyMutation,
  useGetPurchaseOrderByIdQuery,
  useGetRequisitionByIdQuery,
  useGetRequisitionReviewByIdQuery,
  useGetRequisitionReviewsQuery,
  useGetRequisitionsNewQuery,
  useGetRequisitionsQuery,
  useGetStakeholdersByTypeByTypeQuery,
  useGetStakeholdersCountQuery,
  usePatchStakeholdersByIdMutation,
  useGetStakeholdersByIdQuery,
  useDeleteStakeholdersByIdMutation,
  usePostStakeholdersMutation,
  useGetStakeholdersQuery,
  usePutSupervisorsByIdMutation,
  usePatchSupervisorsByIdMutation,
  useDeleteSupervisorsByIdMutation,
  usePostSupervisorsMutation,
  useGetSupervisorsQuery,
  usePostUnitMutation,
  useGetUnitsByProjectByProjectIdQuery,
  useGetUnitsCountQuery,
  usePutUnitsByIdMutation,
  usePatchUnitsByIdMutation,
  useGetUnitsByIdQuery,
  useDeleteUnitsByIdMutation,
  usePatchUnitsMutation,
  useGetUnitsQuery,
  useGetUserByIdQuery,
  useGetUsersCountQuery,
  usePostUsersLoginMutation,
  usePostUsersLogoutMutation,
  useGetUsersMeQuery,
  usePostUsersResetPasswordMutation,
  usePostUsersMutation,
  useGetUsersQuery,
  useGetUsersWithReviewsQuery,
  useGetWhoAmIQuery,
} = injectedRtkApi;
