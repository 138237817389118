const locationLocationFormModel = {
    formId: 'locationForm',
    formField: {
      developmentId: {
        name: 'developmentId',
        label: 'development',
        required: true,
        requiredErrorMsg: 'development is required',
      },
      locationCode: {
        name: 'locationCode',
        label: 'locationCode',
        required: true,
        requiredErrorMsg: 'locationCode is required',
      },
      description: {
        name: 'description',
        label: 'Description',
        required: true,
        requiredErrorMsg: 'Description code is required',
      },
     
    },
    initialValues: {
        locationCode: '',
        developmentId:'',
        description:''    
    },
  };
  
  export type createInventoryLocationModelType = {
    locationCode: string;
    developmentId: string;
    description: string;
  };
  
  export default locationLocationFormModel;
  