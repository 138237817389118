import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, IconButton, Fab } from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { ArrowBack, Add } from "@mui/icons-material";
import PageWithHeader from "../common/PageWithHeader";
import {push} from "connected-react-router/immutable";
import {useAppDispatch} from "../../redux/hooks";

interface Equipment {
  id: GridRowId;
  brand: string;
  model: string;
  type: string;
  startHours: number;
}

const Manage: React.FC = () => {
  const dispatch = useAppDispatch();

  const [equipmentList, setEquipmentList] = useState<Equipment[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingEquipment, setEditingEquipment] = useState<Equipment | null>(null);

  const [formValues, setFormValues] = useState<Partial<Equipment>>({
    brand: "",
    model: "",
    type: "",
    startHours: 0,
  });

  const handleDialogOpen = (equipment?: Equipment) => {
    setEditingEquipment(equipment || null);
    setFormValues(equipment || { brand: "", model: "", type: "", startHours: 0 });
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setEditingEquipment(null);
  };

  const handleFormChange = (field: keyof Equipment, value: string | number) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = () => {
    if (editingEquipment) {
      setEquipmentList((prev) =>
        prev.map((item) => (item.id === editingEquipment.id ? { ...item, ...formValues } : item))
      );
    } else {
      setEquipmentList((prev) => [
        ...prev,
        { ...formValues, id: Date.now() } as Equipment,
      ]);
    }
    handleDialogClose();
  };

  const handleDelete = (id: GridRowId) => {
    setEquipmentList((prev) => prev.filter((item) => item.id !== id));
  };

  const columns: GridColDef[] = [
    { field: "brand", headerName: "Brand", flex: 1 },
    { field: "model", headerName: "Model", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button variant="contained" color="primary" onClick={() => handleDialogOpen(params.row as Equipment)}>
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(params.row.id)}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <PageWithHeader>
      <Box padding={3}>
        <Box display="flex" alignItems="center" gap={2} mb={3}>
          <IconButton onClick={() => dispatch(push(`/plant-reports`))}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h4" fontWeight="bold">
            Manage Equipment
          </Typography>
        </Box>

        <DataGrid
          rows={equipmentList}
          columns={columns}
          autoHeight
          sx={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              fontWeight: "bold",
              fontSize: "1rem",
            },
          }}
        />

        <Fab
          color="primary"
          aria-label="add"
          onClick={() => handleDialogOpen()}
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
          }}
        >
          <Add />
        </Fab>
      </Box>

      <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>
          {editingEquipment ? "Edit Equipment" : "Add Equipment"}
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} mt={1}>
            <TextField
              label="Brand"
              value={formValues.brand || ""}
              onChange={(e) => handleFormChange("brand", e.target.value)}
              fullWidth
            />
            <TextField
              label="Model"
              value={formValues.model || ""}
              onChange={(e) => handleFormChange("model", e.target.value)}
              fullWidth
            />
            <TextField
              label="Type"
              value={formValues.type || ""}
              onChange={(e) => handleFormChange("type", e.target.value)}
              fullWidth
            />
            <TextField
              label="Start Hours"
              type="number"
              value={formValues.startHours || 0}
              onChange={(e) => handleFormChange("startHours", parseInt(e.target.value, 10))}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </PageWithHeader>
  );
};

export default Manage;
