import React, { useState } from 'react';
import {
    Inventory, 
  useGetInventoriesQuery,
} from '../../../redux/vmsApi';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const InventoriesList: React.FC = () => {
  const { data:inventories, isLoading, isError } = useGetInventoriesQuery({});
  const [selectedinventory, setSelectedinventory] = useState<Inventory | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleEditClick = (inventory:Inventory) => {
    setSelectedinventory(inventory);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedinventory(null);
  };


  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Error fetching inventories.</div>;
  }

  return (
    <Box p={10}>
      <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#123456' }}>
        View inventories
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Inventory Code</TableCell>
              <TableCell>Description</TableCell>
             
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inventories?.map((inventory:Inventory) => (
              <TableRow key={inventory.id}>
                <TableCell>{inventory.inventoryCode}</TableCell>
                <TableCell>{inventory.description}</TableCell>
                <TableCell>
                  <Button onClick={() => handleEditClick(inventory)}>Edit</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
     
    </Box>
  );
};

export default InventoriesList;
