import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {
  Assignment,
  LocalShipping,
  RateReview,
  Description,
  Home, Dashboard
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { push } from "connected-react-router/immutable";
import { logoutUser } from "../../redux/reducers/authSlice";
import useIsMobile from "../../hooks/useIsMobile";

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const currentUser = useAppSelector(state => state.auth.user)
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    setAnchorElUser(null);
    dispatch(logoutUser()).then(() => {
      dispatch(push('/'))
    });
  }

  const handleSupport = () => {
    window.location.href = 'mailto:it@westprop.com?subject=Support Request';
  };

  const handleRestrictedClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
  };

  const userAccess = {
    invite: !!(currentUser && currentUser.roles?.includes('vms')),
    procurement: !!(currentUser && currentUser.roles?.includes('procurement')),
    reviews: true,
    agreements: !!(currentUser && currentUser.roles?.includes('agreement')),
    payroll: !!(currentUser && currentUser.roles?.includes('payroll')),
    propertyRegister: !!(currentUser && currentUser.roles?.includes('propertyRegister')),
    accessControl: true,
    cSuite: !!(currentUser && currentUser.roles?.includes('cSuite')),
    siteInspector: !!(currentUser && currentUser.roles?.includes('siteInspector')),
    wdc: !!(currentUser && currentUser.roles?.includes('admin')),
  };

  const menuItems = [
    { id: 1, title: 'C Suite Dashboard', link: `/mid`, icon: <Dashboard />, access: userAccess.cSuite },
    { id: 2, title: 'Performance System', link: `/reviews`, icon: <RateReview />, access: userAccess.reviews },
    { id: 3, title: 'Procurement', link: `/procurement`, icon: <LocalShipping />, access: userAccess.procurement },
    { id: 4, title: 'Invite Management', link: `/invite`, icon: <Assignment />, access: userAccess.invite },
    { id: 5, title: 'Client Agreements', link: `/create-agreement`, icon: <Description />, access: userAccess.agreements },
    { id: 6, title: 'Property Register', link: `/property-register`, icon: <Home />, access: userAccess.propertyRegister },
  ];

  return (
    <AppBar position="static" color="primary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{
            display: { xs: 'none', md: 'flex' },
            mr: 1
          }}>
            <Link to={'/'}>
              <img
                alt="WestProp Logo"
                src="https://staging.westprop.com/wp-content/uploads/2023/08/WestProp-Logo-White.png"
                style={{ marginRight: 20, width: 150 }}
              />
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-start' }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {menuItems.map((item) => (
                item.access ? (
                  <MenuItem key={item.id} onClick={handleCloseNavMenu}>
                    <Link to={item.link} style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: isMobile ? 'flex-start' : 'center', justifyContent: isMobile ? 'flex-start' : 'center', width: '100%' }}>
                      {item.icon}
                      {isMobile && <Typography sx={{ ml: 1 }}>{item.title}</Typography>}
                    </Link>
                  </MenuItem>
                ) : (
                  <Tooltip title="You do not have access to this function" arrow key={item.id}>
                    <MenuItem onClick={handleRestrictedClick}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', color: 'inherit' }}>
                        {item.icon}
                        {isMobile && <Typography sx={{ ml: 1 }}>{item.title}</Typography>}
                      </Box>
                    </MenuItem>
                  </Tooltip>
                )
              ))}
            </Menu>
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
            <Link to={'/'}>
              <img
                alt="WestProp Logo"
                src="https://staging.westprop.com/wp-content/uploads/2023/08/WestProp-Logo-White.png"
                style={{ marginRight: 20, width: 100 }}
              />
            </Link>

          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
            {menuItems.map((item) => (
              item.access ? (
                <Tooltip title={item.title} arrow key={item.id}>
                  <Button
                    sx={{ my: 2, color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    component={Link}
                    to={item.link}
                  >
                    {item.icon}
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="You do not have access to this function" arrow key={item.id}>
                  <Box sx={{ my: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                      sx={{ color: 'white' }}
                      onClick={handleRestrictedClick}
                    >
                      {item.icon}
                    </Button>
                  </Box>
                </Tooltip>
              )
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
            <Button
              variant="outlined"
              color="inherit"
              onClick={handleSupport}
              sx={{ marginRight: 2 }}
            >
              Support
            </Button>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar>{currentUser?.name?.slice(0, 1) ?? ''}</Avatar>
            </IconButton>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key={'logout'} onClick={handleLogout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
