
const itemsIssuedFormModel = {
    formId: 'itemsIssuedForm',
    formField: {
      itemId: {
        name: 'itemId',
        label: 'Item',
        required: true,
        requiredErrorMsg: 'Item is required',
      },
      unitId: {
        name: 'unitId',
        label: 'Housing Unit',
        required: true,
        requiredErrorMsg: 'Housing Unit is required',
      },
      stock: {
        name: 'stock',
        label: 'Stock',
        required: true,
        requiredErrorMsg: 'Stock is required',
      },
      totalValue: {
        name: 'totalValue',
        label: 'Total Value',
        required: true,
  
      },
    },
    initialValues: {
        itemId: '',
        unitId:'',
        stock:'',
        totalValue:''     
    },
  };
  
  export type createItemsIssuedModelType = {
    itemId: string;
    unitId: string;
    stock: string;
    totalValue:string ;
  };
  
  export default itemsIssuedFormModel;
  