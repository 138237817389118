import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography } from "@mui/material";
import { Form, Formik } from 'formik';



import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enGB } from 'date-fns/locale'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ItemLocation, NewItemLocation, usePostItemLocationsMutation } from '../../../redux/vmsApi';
import createItemLocationModel, { } from '../models/createItemLocationModel';
import LoadingCover from '../../common/LoadingCover';
import CreateItemsLocationForm from '../forms/CreateItemLocationForm';
import { createItemLocationModelType } from '../models/createItemsLocationModel';




let newItemLocation: NewItemLocation;
type MyFormValues = createItemLocationModelType;

const createItemLocationModelInitial = createItemLocationModel.initialValues

type Props = {
  onSuccess?: (ItemLocation: ItemLocation) => void;
};

const CreateItemLocationPage: React.FC<Props> = ({ onSuccess }) => {
  const [createItemLocation, { isSuccess, isLoading }] = usePostItemLocationsMutation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);

  useEffect(() => {
    setShowOverlay(isLoading || isSuccess);
    if (isSuccess) {
      setTimeout(() => {
        setShowOverlay(false)
        setShowSuccessMsg(true)
      }, 3000);
    }
  }, [isLoading, isSuccess]);

  const initialValues: MyFormValues = {
    ...createItemLocationModelInitial,
  };



  const handleSubmit = async (values: MyFormValues) => {
    const totalValue = values.totalValue.toString();
    const stock = values.stock.toString();  
    const newItemLocation = {
      itemId: values.itemId,
      locationId: values.locationId,
      stock: parseFloat(stock),
      totalValue: parseFloat(totalValue),
    };

    console.log("+++++++data++++++++", newItemLocation)


    setShowOverlay(true)
    try {
      const result = await createItemLocation({ newItemLocation }).unwrap();
      setShowOverlay(false);
      setShowOverlay(false);

      if (onSuccess) {
        onSuccess(result);
      }
    } catch (error) {

      setShowOverlay(false);
      console.error("Error submitting form:", error);
    }
  }

  if (showSuccessMsg) {
    return (
      <Typography>Thank you, your response has been received</Typography>
    )
  }

  return (
    <React.Fragment>
      {showOverlay && <LoadingCover isSuccess={isSuccess} />}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}

      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <Form>
            <Stack spacing={4} width="100%" p={10} pb={5}>
              <CreateItemsLocationForm />
              <Button
                variant="contained"
                type="submit"
              >
                Submit
              </Button>
            </Stack>
          </Form>
        </LocalizationProvider>
      </Formik>
    </React.Fragment>
  )
}

export default CreateItemLocationPage;
